<template>
  <div class="register">
    <van-nav-bar title="注册" left-arrow @click-left="onClickLeft" />
    <div class="wrap">
      <div class="title">欢迎回来</div>
      <div class="notes">创建账户并探索我们为您准备的服务</div>
      <div class="form-wrap">
        <div class="input-wrap">
          <span class="label">手机号</span>
          <input type="text" v-model="tel" />
        </div>
        <div class="input-wrap">
          <span class="label">手机验证码</span>
          <div style="display: flex; align-items: center">
            <input
              type="text"
              style="width: 211px"
              v-model="verification"
            />
            <span style="font-size: 12px; flex: 1; text-align: center"
              v-show="zhaohuipsd"
              @click="yanzhengmaBtn()" >获取手机验证码</span>
            <span class="passwordYanzhengmaBtn"  v-show="!zhaohuipsd">{{zhaohuiTime}}s后获取</span>
          </div>
        </div>
        <div class="input-wrap">
          <span class="label">密码</span>
          <input type="password" v-model="password" />
        </div>
        <div class="input-wrap" style="margin-bottom: 0">
          <span class="label">确认密码</span>
          <input type="password" v-model="confirmPassword" />
        </div>
        <div class="privacy">
          <van-checkbox v-model="checked"></van-checkbox>
          <span
            >注册账号代表您已接受WODELAN的<b>隐私政策</b>和<b>销售条款</b></span
          >
        </div>
        <div class="input-wrap">
          <van-button type="primary" @click="submitBtn()">提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      form: {},
      checked: false,
      tel:"",
      verification:'',
      password:'',
      confirmPassword:'',
      yanzhengma:true,
      yanzhengmaNum:'',
      yanzhengmaTime:0,//注册验证码倒计时
      regestyanzhengma:true,
      yanzhengmapsd:'',
      yanzhengmaList:'',
      zhaohuipsd:true,
      zhaohuiTime:0,
    };
  },
  created() {
    this.$emit("header", false);
  },
  methods: {
    onClickLeft() {
      history.go(-1)
    },
    yanzhengmaBtn(){
      if(this.tel == ''){
        this.$toast('请输入手机号');  
        return false
      }
      let data = {
          type:'register',
          mobile:this.tel
      }
      this.axios.post('https://api.wodelan.com/api/user/sendcaptcha',data).then(res =>{
          console.log(res)
          if(res.data.code == 1){
              this.$toast(res.data.msg);  
              this.yanzhengmaList = res.data.data
              this.zhaohuipsd = false
              this.zhaohuiTime = 60
              var auth_timetimer  = setInterval(() =>{
                  this.zhaohuiTime --;
                  if(this.zhaohuiTime <= 0){
                      this.zhaohuipsd = true;
                      clearInterval(auth_timetimer)
                  }
              },1000)   
          }else{
              this.$toast(res.data.msg);
          }
      })  
    },
    submitBtn(){
      if(this.tel == ''){
        this.$toast('请输入手机号');  
        return false
      }
      if(this.verification == ''){
        this.$toast('请输入手机验证码');  
        return false
      }
      if(this.password == ''){
        this.$toast('请输入密码');  
        return false
      }
      if(this.confirmPassword == ''){
        this.$toast('请输入确认密码');  
        return false
      }
      if(this.checked == false){
        this.$toast('请勾选隐私政策');
        return false
      }
      if(this.password != this.confirmPassword){
        this.$toast('两次输入的密码不一直');
        return false
      }
      let data = {
        username : this.tel,
        password : this.password,
        mobile : this.tel,
        code : this.verification
      }
      this.axios.post('https://api.wodelan.com/api/user/register',data).then(res =>{
        console.log(res)
        if(res.data.code == 1){
            this.$toast(res.data.msg);
            this.$router.push({path:'./register-success',query:{tel:this.tel}})
        }else{
            this.$toast(res.data.msg);
        }
      })
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.register {
  .wrap {
    padding: 19px 13px;
    .title {
      font-size: 25px;
      font-weight: bold;
    }
    .notes {
      margin-top: 8px;
      font-size: 16px;
    }
    .form-wrap {
      margin-top: 35px;
      .input-wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 34px;
        .label {
          font-size: 16px;
          margin-bottom: 14px;
        }
        input {
          height: 50px;
          font-size: 16px;
          padding: 0 10px;
        }
      }
      .privacy {
        display: flex;
        align-items: center;
        margin: 18px 0;
        span {
          font-size: 12px;
          margin-left: 10px;
        }
      }
      .van-button {
        background: #000000;
        color: #ffffff;

        height: 55px;
        border-color: #000;
        font-size: 18px;
      }
    }
  }
}
.passwordYanzhengmaBtn{
  font-size: 3.33vw;
  margin-left: 2vw;
}
</style>
