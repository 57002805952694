<template>
  <div class="homeTop">
    <!-- tab切换 -->
    <div class="topTab">
      <div
        class="tabList"
        v-for="item in tabList"
        :key="item.id"
        @click="clickId(item.id)"
        :class="item.id == tabId ? 'tabListActvie' : ''"
      >
        {{ item.name }}
      </div>
    </div>
    <!-- 我的账户内容 -->
    <div class="my" v-if="tabId == 1">
      <div class="my_title">我的账户</div>
      <div class="my_phone">您好，{{uname}}</div>
      <div class="my_text">
        您可在我的账户中追踪订单信息，修改我的账户信息，管理配送地址信息以及查看清单中的表款信息。
      </div>
      <div class="my_ddxx">
        <div class="my_ddxx_text">订单信息</div>
      </div>
      <div class="orderList" v-for="item in account" :key="item.id">
        <div class="order_right">
          <div class="goods_id">
            <div class="order_id_text">订单号</div>
            <div class="order_id_list">{{ item.order_no }}</div>
          </div>
          <div class="order_id">
            <div class="time_text">日期</div>
            <div class="time_list">{{ item.createtime }}</div>
          </div>
          <div class="order_id goods_id">
            <div class="order_id_text">总计</div>
            <div class="order_id_list">￥{{ item.amount }}</div>
          </div>
          <div class="order_id">
            <div class="time_text">订单状态</div>
            <div class="time_list">{{ item.status }}</div>
          </div>
        </div>
        <dir class="order_left" @click="linktoDetails()">
          <img src="../../assets/image/left.png" alt="" />
        </dir>
      </div>
      <div class="my_ddxx">
        <div class="my_ddxx_text">账户信息</div>
      </div>
      <div class="mymessage">
        <div class="myPhone">{{unames}}</div>
        <div class="mymessageBtn" @click="editMessage()">编辑信息</div>
      </div>
      <div class="my_ddxx">
        <div class="my_ddxx_text">配送地址</div>
      </div>
      <div class="mymessage">
        <div class="myPhone" v-if="morenAdd">{{morenAdd.province}}{{morenAdd.city}}{{morenAdd.area}}{{morenAdd.ship_addr}}</div>
        <div class="myPhone" v-else></div>
        <div class="mymessageBtn" @click="addAddress()">添加配送地址</div>
      </div>
    </div>
    <!-- 我的手表 -->
    <div v-if="tabId == 2" class="mywatch">
      <div class="mywatch_tab">
        <div
          class="cpdd"
          @click="mywatch(1)"
          :class="watchid == 1 ? 'cpddActive' : ''"
        >
          成品订单
        </div>
        <div
          class="cpdd"
          @click="mywatch(2)"
          :class="watchid == 2 ? 'cpddActive' : ''"
        >
          定制进度
        </div>
      </div>
      <div v-if="watchid == 1">
        <div class="dzjdRow" v-for="item in mydingdan" :key="item.id">
          <div class="dz_top">
            <div class="ddh">
              订单号<span>{{ item.order_no }}</span>
            </div>
            <div class="status">{{ item.status }}</div>
          </div>
          <div class="watchContent">
            <div class="watchImg"><img :src="item.goods_thumb" alt="" /></div>
            <div class="watchDetails">
              <div class="watchDetailsList">
                <div class="watchDetailsRight">产品系列</div>
                <div class="watchDetailsLeft">{{ item.amount }}</div>
              </div>
              <div class="watchDetailsList">
                <div class="watchDetailsRight">{{ item.goods_name }}</div>
                <div class="watchDetailsLeft">x{{ item.num }}</div>
              </div>
              <div class="watchDetailsList">
                <div class="watchDetailsRight">表带尺寸（厘米）{{ item.strap_size }}</div>
                <!-- <div class="watchDetailsLeft"></div> -->
              </div>
            </div>
          </div>
          <div class="watchTime">
            <div class="timeRight">
              日期<span>{{ item.createtime }}</span>
            </div>
            <div class="timeLeft">
              总计¥<span>{{ item.amount*item.num }}</span>
            </div>
          </div>
          <div class="footerBtn" v-if="item.status == '待支付'">
            <div class="cancel" @click="cancelOrder(item.id)">取消订单</div>
            <div class="pay" @click="continuePay(item.id)">继续支付</div>
          </div>
          <div class="footerBtn" v-else>
            <div class="pay" @click="watchXiangqing(item.id)">查看详情</div>
          </div>
        </div>
      </div>
      <div v-if="watchid == 2">
        <div class="dzjdRow" v-for="item in dingzhiList" :key="item.id">
          <div class="dz_top">
            <div class="ddh">
              订单号<span>{{ item.order_no }}</span>
            </div>
            <div class="status">{{ item.status }}</div>
          </div>
          <div class="watchContent">
            <div class="watchImg"><img :src="item.thumb" alt="" /></div>
            <div class="watchDetails">
              <div class="watchDetailsList">
                <div class="watchDetailsRight">{{item.series}}</div>
                <div class="watchDetailsLeft">￥{{ item.amount/item.num }}</div>
              </div>
              <div class="watchDetailsList">
                <div class="watchDetailsRight">{{ item.goods_name }}</div>
                <div class="watchDetailsLeft">x{{ item.num }}</div>
              </div>
              <div class="watchDetailsList">
                <div class="watchDetailsRight">表带尺寸（厘米）{{ item.strap_size }}</div>
                <!-- <div class="watchDetailsLeft"></div> -->
              </div>
            </div>
          </div>
          <div class="watchTime">
            <div class="timeRight">
              日期<span>{{ item.createtime }}</span>
            </div>
            <div class="timeLeft">
              总计¥<span>{{ item.amount }}</span>
            </div>
          </div>
          <div class="footerBtn" v-if="item.status == '制作中'">
            <div class="cancel">取消订单</div>
            <div class="pay" @click="continuePay(item.id)">继续支付</div>
          </div>
          <div class="footerBtn" v-else>
            <div class="pay" @click="watchXiangqing(item.id)">查看详情</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 配送地址 -->
    <div v-if="tabId == 3" class="giveaddress">
      <div class="address-list">
        <div
          class="address-item"
          v-for="(item, index) in addressDetails"
          :key="index"
        >
          <div class="item-tp" >
            <div class="info" @click="linktoPay(item)">
              <span>{{ item.ship_name }},{{ item.ship_tel }}</span>
              <span class="address-detail">{{ item.province }}{{ item.city }}{{ item.area }}{{item.ship_addr}}</span>
            </div>
            <van-icon name="arrow" @click="editaddress(item)" />
          </div>
          <div class="item-bt">
            <div class="ischeck">
              <van-checkbox v-model="item.is_default" @change="morenBtn(item.id)" shape="square">设为默认地址</van-checkbox>
              <!-- <span @click="changeAddress(item, index)"></span> -->
            </div>
            <span @click="handleDelete(item.id)">删除</span>
          </div>
        </div>
        <div class="add-address" @click="handleAdd">添加新地址</div>
      </div>
    </div>
    <!-- 优惠券包 -->
    <div v-if="tabId == 4" class="accountinfo">

      <div class="couponQH_max">
        <div
          :class="couponIndex == index ? 'couponActive' : ''"
          class="couponQH"
          v-for="(item, index) in couponTab"
          :key="index"
          @click="getCoupponList(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="repair-info">
        <!-- 全部 -->
        <div class="" v-if="couponIndex == 0" >
          <div class="popMax">
              <div class="couponPop">
                  <div class="couponList">
                    <div class="coupon"
                        v-for="item in couponCardList" 
                        :key="item"
                        :class="item.status != 0?'coupon_sx':''"
                    >
                        <div class="couponCho"><span>√</span></div>
                        <div class="couponTitle">
                            <img src="../../assets/image/quan4.png" alt="">
                            <div>{{item.name}}</div>
                        </div>
                        <div class="couponContent">
                            <div class="couponPrice"><span>￥</span>{{item.amount}}</div>
                            <div>使用范围: {{item.type}}</div>
                            <div>{{item.start_time}} 至 {{item.end_time}} 有效</div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
        <!-- 未使用 -->
        <div class="" v-if="couponIndex == 1" >
          <div class="popMax">
              <div class="couponPop">
                  <div class="couponList">
                    <div class="coupon"
                        v-for="item in couponCardList" 
                        :key="item"
                        :class="item.status != 0?'coupon_sx':''"
                    >
                        <div class="couponCho"><span>√</span></div>
                        <div class="couponTitle">
                            <img src="../../assets/image/quan4.png" alt="">
                            <div>{{item.name}}</div>
                        </div>
                        <div class="couponContent">
                            <div class="couponPrice"><span>￥</span>{{item.amount}}</div>
                            <div>使用范围: {{item.type}}</div>
                            <div>{{item.start_time}} 至 {{item.end_time}} 有效</div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
        <!-- 已使用 -->
        <div class="" v-if="couponIndex == 2" >
          <div class="popMax">
              <div class="couponPop">
                  <div class="couponList">
                    <div class="coupon"
                        v-for="item in couponCardList" 
                        :key="item"
                        :class="item.status != 0?'coupon_sx':''"
                    >
                        <div class="couponCho"><span>√</span></div>
                        <div class="couponTitle">
                            <img src="../../assets/image/quan4.png" alt="">
                            <div>{{item.name}}</div>
                        </div>
                        <div class="couponContent">
                            <div class="couponPrice"><span>￥</span>{{item.amount}}</div>
                            <div>使用范围: {{item.type}}</div>
                            <div>{{item.start_time}} 至 {{item.end_time}} 有效</div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
        <!-- 已失效 -->
        <div class="" v-if="couponIndex == 3" >
          <div class="popMax">
              <div class="couponPop">
                  <div class="couponList">
                    <div class="coupon"
                        v-for="item in couponCardList" 
                        :key="item"
                        :class="item.status != 0?'coupon_sx':''"
                    >
                        <div class="couponCho"><span>√</span></div>
                        <div class="couponTitle">
                            <img src="../../assets/image/quan4.png" alt="">
                            <div>{{item.name}}</div>
                        </div>
                        <div class="couponContent">
                            <div class="couponPrice"><span>￥</span>{{item.amount}}</div>
                            <div>使用范围: {{item.type}}</div>
                            <div>{{item.start_time}} 至 {{item.end_time}} 有效</div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- 账户信息 -->
    <div v-if="tabId == 5" class="accountinfo">
      <div class="account-wrap">
        <van-form ref="form">
          <!-- 姓名 -->
          <van-field label="姓名" v-model="name" name="name"></van-field>
          <!-- 性别 -->
          <!-- <div class="select-wrap">
            <van-field
              v-model="gender"
              readonly
              name="gender"
              label="性别"
              @click="showGender = true"
            />
            <van-icon name="arrow-down" />
          </div> -->
        <div class="select_row">
          <div class="select_name">*性别</div>
          <select name="" id="" class="selectList" v-model="genderRow">
            <option  v-for="(item,index) in genderList" :key="index" :value="item.id">{{item.name}}</option>
          </select>
        </div>
          <!-- 出生 年 月 日 -->
          <div class="select-wrap">
            <van-field
              v-model="form.birth"
              readonly
              name="birth"
              label="出生 年 月 日"
              @click="openBirth"
            />
            <van-icon name="arrow-down" />
          </div>
          <!-- 手机号 -->
          <van-field label="手机号" v-model="tel" name="tel"> </van-field>
          <div class="select-wrap" style="margin-bottom: 45px">
            <van-field
              v-model="code"
              name="code"
              label="验证码"
              style="width: 60%"
            />
            <span style="font-size: 12px; flex: 1; text-align: center"
              v-show="zhaohuipsd"
              @click="yanzhengmaBtn()">获取手机验证码</span
            >
            <span class="passwordYanzhengmaBtn"  v-show="!zhaohuipsd">{{zhaohuiTime}}s后获取</span>
          </div>
          <van-popup v-model:show="showGender" position="bottom">
            <van-picker
              :columns="genders"
              @change="onChange"
              @confirm="onConfirm"
              @cancel="showGender = false"
            />
          </van-popup>
          <van-popup v-model:show="showBirth" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="showBirth = false"
              @confirm="onConfirmBirth"
              :formatter="formatter"
            />
          </van-popup>
          <van-button type="primary" block @click="save">保存</van-button>
        </van-form>
      </div>
    </div>
    <!-- 维修保养 -->
    <div v-if="tabId == 6" class="repair">
      <div class="repair-tab">
        <div
          :class="curIndex == index ? 'active-tab-item tab-item' : 'tab-item'"
          v-for="(item, index) in repairTab"
          :key="index"
          @click="handleTab(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="repair-info">
        <!-- 全部 -->
        <div class="" v-if="curIndex == 0" >
          <div class="order-info" v-for="item in maintainList" :key="item.id">
            <div class="number-time">
              <span>订单号<b>{{item.order_no}}</b></span>
              <span>日期<b>{{item.createtime}}</b></span>
            </div>
            <div class="order-status">
              <span>订单状态
                 <b v-if="item.status == 0"> 已寄出</b>
                 <b v-else-if="item.status == 1"> 已确认</b>
                 <b v-else-if="item.status == 2"> 待评估</b>
                 <b v-else-if="item.status == 3"> 待支付</b>
                 <b v-else-if="item.status == 4"> 已支付</b>
                 <b v-else-if="item.status == 5"> 已寄回</b>
                 <b v-else-if="item.status == 6"> 已完成</b>
              </span>
            </div>
            <div class="name-wrap">
              <div class="name">
                <div class="name-lt">产品名称 <span>{{item.brand_id}}</span></div>
                <div class="name-gt">需求 <span>{{item.maintenance_id}}</span></div>
              </div>
              <div class="mail">维修/保养完成是否需要邮寄</div>
              <div class="address">
               {{item.is_delivery}}
               <span v-if="item.is_delivery == '是' ">({{item.recipient}},{{item.phone}},{{item.address}})</span>
               <span v-else></span>
              </div>
            </div>
            <div class="remark-text">
              <div class="remark-title">备注说明</div>
              <div class="remark-content">
                {{item.content}}
              </div>
              <div class="remark-img">
                <div class="img-item" v-for="(v, i) in item.thumb" :key="i">
                  <img :src="v" alt="" @click="examineDt(item.thumb,i)" />
                </div>
              </div>
              <div
                class="remark-content"
                style="display: flex; flex-direction: column"
              >
                <span style="margin-bottom: 3px">维修内容</span>
                <span
                  >{{item.maintenance_content}}</span
                >
              </div>
              <div class="remark-img">
                <div class="img-item" v-for="(src, i) in item.main_thumb" :key="i">
                  <img :src="src" alt="" @click="examineDt(item.main_thumb,i)" />
                </div>
              </div>
              <div class="repair-btn">
                <div class="cost">费用<b v-if="item.status >= 3">{{item.price}}</b><b v-else>待评估</b></div>
                <van-button type="primary" block v-if="item.status == 3" @click="gopay(item)">立即支付</van-button>
              </div>
            </div>
          </div>
        </div>
        <!-- 维修 -->
        <div class="" v-if="curIndex == 1" >
          <div class="order-info" v-for="item in maintainList" :key="item.id">
          <div class="number-time">
              <span>订单号<b>{{item.order_no}}</b></span>
              <span>日期<b>{{item.createtime}}</b></span>
            </div>
            <div class="order-status">
              <span>订单状态
                 <b v-if="item.status == 0"> 已寄出</b>
                 <b v-else-if="item.status == 1"> 已确认</b>
                 <b v-else-if="item.status == 2"> 待评估</b>
                 <b v-else-if="item.status == 3"> 待支付</b>
                 <b v-else-if="item.status == 4"> 已支付</b>
                 <b v-else-if="item.status == 5"> 已寄回</b>
                 <b v-else-if="item.status == 6"> 已完成</b>
              </span>
            </div>
            <div class="name-wrap">
              <div class="name">
                <div class="name-lt">产品名称 <span>{{item.brand_id}}</span></div>
                <div class="name-gt">需求 <span>{{item.maintenance_id}}</span></div>
              </div>
              <div class="mail">维修/保养完成是否需要邮寄</div>
              <div class="address">
               {{item.is_delivery}}
               <span v-if="item.is_delivery == '是' ? true : false">({{item.recipient}},{{item.phone}},{{item.address}})</span>
              </div>
            </div>
            <div class="remark-text">
              <div class="remark-title">备注说明</div>
              <div class="remark-content">
                {{item.content}}
              </div>
              <div class="remark-img">
                <div class="img-item" v-for="(v, i) in item.thumb" :key="i">
                  <img :src="v" alt="" @click="examineDt(item.thumb,i)" />
                </div>
              </div>
              <div
                class="remark-content"
                style="display: flex; flex-direction: column"
              >
                <span style="margin-bottom: 3px">维修内容</span>
                <span
                  >{{item.maintenance_content}}</span
                >
              </div>
              <div class="remark-img">
                <div class="img-item" v-for="(src, i) in item.main_thumb" :key="i">
                  <img :src="src" alt="" @click="examineDt(item.main_thumb,i)" />
                </div>
              </div>
              <div class="repair-btn">
                <div class="cost">费用<b v-if="item.status >= 3">{{item.price}}</b><b v-else>待评估</b></div>
                <van-button type="primary" block v-if="item.status == 3" @click="gopay(item.id)">立即支付</van-button>
              </div>
            </div>
          </div>
        </div>
        <!-- 保养 -->
        <div class="" v-if="curIndex == 2" >
          <div class="order-info" v-for="item in maintainList" :key="item.id">
          <div class="number-time">
              <span>订单号<b>{{item.order_no}}</b></span>
              <span>日期<b>{{item.createtime}}</b></span>
            </div>
            <div class="order-status">
              <span>订单状态
                 <b v-if="item.status == 0"> 已寄出</b>
                 <b v-else-if="item.status == 1"> 已确认</b>
                 <b v-else-if="item.status == 2"> 待评估</b>
                 <b v-else-if="item.status == 3"> 待支付</b>
                 <b v-else-if="item.status == 4"> 已支付</b>
                 <b v-else-if="item.status == 5"> 已寄回</b>
                 <b v-else-if="item.status == 6"> 已完成</b>
              </span>
            </div>
            <div class="name-wrap">
              <div class="name">
                <div class="name-lt">产品名称 <span>{{item.brand_id}}</span></div>
                <div class="name-gt">需求 <span>{{item.maintenance_id}}</span></div>
              </div>
              <div class="mail">维修/保养完成是否需要邮寄</div>
              <div class="address">
               {{item.is_delivery}}
               <span v-if="item.is_delivery == '是' ? true : false">({{item.recipient}},{{item.phone}},{{item.address}})</span>
              </div>
            </div>
            <div class="remark-text">
              <div class="remark-title">备注说明</div>
              <div class="remark-content">
                {{item.content}}
              </div>
              <div class="remark-img">
                <div class="img-item" v-for="(v, i) in item.thumb" :key="i">
                  <img :src="v" alt="" @click="examineDt(item.thumb,i)" />
                </div>
              </div>
              <div
                class="remark-content"
                style="display: flex; flex-direction: column"
              >
                <span style="margin-bottom: 3px">维修内容</span>
                <span
                  >{{item.maintenance_content}}</span
                >
              </div>
              <div class="remark-img">
                <div class="img-item" v-for="(src, i) in item.main_thumb" :key="i">
                  <img :src="src" alt="" @click="examineDt(item.main_thumb,i)" />
                </div>
              </div>
              <div class="repair-btn">
                <div class="cost">费用<b v-if="item.status >= 3">{{item.price}}</b><b v-else>待评估</b></div>
                <van-button type="primary" block v-if="item.status == 3" @click="gopay(item.id)">立即支付</van-button>
              </div>
            </div>
          </div>
        </div>
        <div class="" v-if="curIndex == 3" >
          <div class="order-info" v-for="item in maintainList" :key="item.id">
          <div class="number-time">
              <span>订单号<b>{{item.order_no}}</b></span>
              <span>日期<b>{{item.createtime}}</b></span>
            </div>
            <div class="order-status">
              <span>订单状态
                 <b v-if="item.status == 0"> 已寄出</b>
                 <b v-else-if="item.status == 1"> 已确认</b>
                 <b v-else-if="item.status == 2"> 待评估</b>
                 <b v-else-if="item.status == 3"> 待支付</b>
                 <b v-else-if="item.status == 4"> 已支付</b>
                 <b v-else-if="item.status == 5"> 已寄回</b>
                 <b v-else-if="item.status == 6"> 已完成</b>
              </span>
            </div>
            <div class="name-wrap">
              <div class="name">
                <div class="name-lt">产品名称 <span>{{item.brand_id}}</span></div>
                <div class="name-gt">需求 <span>{{item.maintenance_id}}</span></div>
              </div>
              <div class="mail">维修/保养完成是否需要邮寄</div>
              <div class="address">
               {{item.is_delivery}}
               <span v-if="item.is_delivery == '是' ? true : false">({{item.recipient}},{{item.phone}},{{item.address}})</span>
              </div>
            </div>
            <div class="remark-text">
              <div class="remark-title">备注说明</div>
              <div class="remark-content">
                {{item.content}}
              </div>
              <div class="remark-img">
                <div class="img-item" v-for="(v, i) in item.thumb" :key="i">
                  <img :src="v" alt="" @click="examineDt(item.thumb,i)" />
                </div>
              </div>
              <div
                class="remark-content"
                style="display: flex; flex-direction: column"
              >
                <span style="margin-bottom: 3px">维修内容</span>
                <span
                  >{{item.maintenance_content}}</span
                >
              </div>
              <div class="remark-img">
                <div class="img-item" v-for="(src, i) in item.main_thumb" :key="i">
                  <img :src="src" alt="" @click="examineDt(item.main_thumb,i)" />
                </div>
              </div>
              <div class="repair-btn">
                <div class="cost">
                  费用
                  <b v-if="item.status >= 3">{{item.price}}</b>
                  <b v-else>待评估</b>
                </div>
                <van-button type="primary" block v-if="item.status == 3" @click="gopay(item.id)">立即支付</van-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="add-repair" @click="$router.push('/add-repair')">
        <van-icon name="add" /><span>新增维保申请</span>
      </div>
    </div>
    <!-- 优惠券弹窗 -->
    <div class="popMaxCoupon" v-if="isShowPop">
        <van-nav-bar title="优惠券折扣" left-arrow @click-left="onClickLeft" />
        <div class="couponPop">
            <div class="couponList">
                <div class="coupon"
                    v-for="item,i in couponCardList" 
                    :key="i"
                    @click="coupon(item,item.user_coupon_id)"
                    :class="item == couponActive ? 'couponActive2' : ''"
                >
                    <div class="couponCho"><span>√</span></div>
                    <div class="couponTitle">
                        <img src="../../assets/image/quan4.png" alt="">
                        <div>{{item.name}}</div>
                    </div>
                    <div class="couponContent">
                        <div class="couponPrice"><span>￥</span>{{item.amount}}</div>
                        <div>使用范围: {{item.type}}</div>
                            <div>{{item.start_time}} 至 {{item.end_time}} 有效</div>
                    </div>
                </div>
                <div class="coupon nocoupon" @click="nocoupon()" :class="couponActive == '' ? 'couponActive2' : ''">
                    <div class="couponCho"><span>√</span></div>
                    不使用优惠券
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="price">折扣后还需支付:<span>￥{{coupon_price}}</span>&nbsp;<span class="price2">￥{{weixiu_price}}</span></div>
            <div class="payNow" @click="payNow">立即支付</div>
        </div>
      </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";

export default {
  data() {
    return {
      user_coupon_id:'',  //选中的优惠券id
      isShowPop:false,  //控制优惠券弹窗
      couponActive:1,  //选中的优惠券
      weixiu_price:'',  //当前选择的维修订单价格
      coupon_price:'',  //使用优惠券后的价格
      m_id:'',  //保存订单id
      couponCardList:[],
      tabList: [
        {
          id: "1",
          name: "我的账户",
        },
        {
          id: "2",
          name: "我的手表",
        },
        {
          id: "3",
          name: "配送地址",
        },
        {
          id: "4",
          name: "优惠券包",
        },
        {
          id: "5",
          name: "账户信息",
        },
        {
          id: "6",
          name: "维修保养",
        },
        {
          id: "7",
          name: "退出登录",
        },
      ],
      tabId: 1,
      watchid: 1,
      checked: false,
      //账户信息
      form: {},
      showGender: false,
      showBirth: false,
      genderList:[
        {
          id:1,
          name:'男'
        },{
          id:0,
          name:'女'
        }
      ],
      genders: ["男", "女"],
      currentDate: new Date(),
      minDate:new Date(1900, 0, 1),
      maxDate: new Date(),
      //维修保养
      repairTab: [{ name: "全部" }, { name: "维修" }, { name: "保养" },{name:"配件加工"}],
      couponTab: [{ name: "全部" }, { name: "未使用" }, { name: "已使用" },{name:"已失效"}],
      curIndex: 0,
      couponIndex:0,  //优惠券包切换
      uname:'',
      account:'',
      userList:'',
      mydingdan:'',
      total:'',
      pagesize:10,
      pagesizeTow:10,
      dingzhiList:'',
      addressDetails:'',
      zhaohuipsd:true,
      tel:'',
      name:'',
      zhaohuiTime:0,
      code:'',
      gender:'',
      birthday:'',
      maintainList:'',
      method:'',
      openId:'',
      pay:'',
      baseUrl:'',
      ismoren:false,
      morenAdd:'',
      unames:'',
      xingbie:'',
    };
  },
  created() {
    this.$emit("header", true);
    
    if(!localStorage.getItem('user_id')){
        this.$toast('请登录');
        this.$router.push({path:'/login'})
    }else if(!localStorage.getItem('name')){
      this.$router.push({path:'//binding-phone'})
    }

    this.uname = localStorage.getItem('name')
    this.unames = localStorage.getItem('username')
    this.ispay = this.$route.query.addressT
    if(this.ispay == 1){
      this.tabId = 3
      console.log('地址')
    }
    this.myaccount()
    this.orderList()
    this.commissionList()
    this.addressRow()
    this.weixiuDetails()
    this.isBlack = true
    this.getDefaultAddress()
    this.userDetails()
    // this.getCoupponList()
  },
  methods: {
    //点击优惠券
    coupon(item,idx){
      console.log(item,idx);
        this.couponActive = item
        this.user_coupon_id = idx  //优惠券id
        if(this.weixiu_price-item.amount>=0){
            this.coupon_price = this.weixiu_price-item.amount
        }else{
            this.coupon_price = 0
        }
    },
    //不使用优惠券
    nocoupon(){
        this.couponActive = ''
        this.coupon_price = this.weixiu_price
        this.user_coupon_id = ''  //优惠券id
    },
    getCoupponList(id){  //获取优惠券列表
        this.couponIndex = id
        let type = ''
        if(id != 0){
          type = id
        }
        let data = {
              user_id : localStorage.getItem('user_id'),
              type : type,
              page : '',
              rows : ''
          }
        this.axios.post('https://api.wodelan.com/api/index/userCouponList',data).then(res =>{
            let data = JSON.parse(res.data.data).data  //优惠券列表
            console.log(data,'优惠券列表');
            this.couponCardList = data
        }) 
    },
    //下单时获取优惠券列表
    getCoupponList_pay(type){
        this.isShowPop = true
        let data = {
            user_id : localStorage.getItem('user_id'),
            type : type   //1购买商品 2维修 3保养
        }
        this.axios.post('https://api.wodelan.com/api/index/getUserCoupon',data).then(res =>{
            let data = JSON.parse(res.data.data).data  //优惠券列表
            console.log('下单时优惠券列表',data);
            this.couponCardList = data
        }) 
    },
    //关闭弹窗
    onClickLeft(){
      this.isShowPop = false
    },
    userDetails(){
      let data = {
        user_id :  localStorage.getItem('user_id'),
      }
      this.axios.post('https://api.wodelan.com/api/user/getUser',data).then(res =>{
          // console.log(res)
          console.log(JSON.parse(res.data.data))
          var wq = JSON.parse(res.data.data)
          this.tel = wq.mobile
          this.name = wq.nickname
          this.birthday = wq.birthday
          this.genderRow = wq.gender
          this.xingbie = wq.gender
          if(this.xingbie == 1){
              this.setValue = '男'
          }else{
              this.setValue = '女'
          }
          var qq = wq.birthday
          console.log(this.form.birth)
          if(wq.birthady != ''){
            this.form.birth = wq.birthday
            console.log(this.form.birth)
            var rr = qq.split('-')
            this.yearValue = rr[0]
            this.yueValue = rr[1]
            this.riValue = rr[2]
          }

          
          
          console.log(typeof(this.setValue))
          console.log(typeof(this.xingbie))
      })
    },
    getDefaultAddress(){
      let data = {
          user_id : localStorage.getItem('user_id')
      }
      this.axios.post('https://api.wodelan.com/api/index/getDefaultAddress',data).then(res =>{
          console.log(JSON.parse(res.data.data))
          this.morenAdd = JSON.parse(res.data.data)
      }) 
    },
    // 查看大图
    examineDt(src,index){
      ImagePreview({
        images:src,
        startPosition:index,
        closeble:true
      })
    },
    myaccount(){
      let data = {
          user_id : localStorage.getItem('user_id')
      }
      this.axios.post('https://api.wodelan.com/api/index/myAccount',data).then(res =>{
          console.log(res)
          console.log(JSON.parse(res.data.data))
          this.account = JSON.parse(res.data.data).order
          this.userList = JSON.parse(res.data.data).address
      })
    },
    linktoDetails(){
      this.tabId = 2
    },
    // 编辑信息
    editMessage(){
      this.tabId = 4
    },
    // 添加配送地址
    addAddress(){
      this.$router.push({path:'./address-detail'})
    },
    orderList(){
      let data ={
          page : 1,
          rows : this.pagesize,
          user_id : localStorage.getItem('user_id')
      }
      this.axios.post('https://api.wodelan.com/api/index/orderList',data).then(res =>{
          console.log(JSON.parse(res.data.data))
          var ss = JSON.parse(res.data.data)
          this.total = ss.length + 1
          console.log(this.total + 1)
          this.mydingdan = ss
      })
    },
    // 定制进度
    commissionList(){
      let data = {
          page:1,
          rows : this.pagesizeTow,
          user_id : localStorage.getItem('user_id')
      }
      this.axios.post('https://api.wodelan.com/api/index/commissionList',data).then(res =>{
          console.log(res)
          console.log(JSON.parse(res.data.data))
          this.dingzhiList = JSON.parse(res.data.data)
      })
    },
    // 取消订单
    cancelOrder(id){
      let data = {
        order_id : id
      }
      this.axios.post('https://api.wodelan.com/api/index/order_cancel',data).then(res =>{
        console.log(res)
        if(res.data.code == 1){
          this.$toast(res.data.msg);
          setTimeout(() => {
            history.go(0)
          }, 1000);
        }else{
          this.$toast(res.data.msg);
        }
      })
    },
    // 地址列表
    addressRow(){
      let data = {
        user_id : localStorage.getItem('user_id')
      }
      this.axios.post('https://api.wodelan.com/api/index/addressList',data).then(res =>{
        console.log(res)
        console.log(JSON.parse(res.data.data))
        this.addressDetails = JSON.parse(res.data.data)
      })
    },
    yanzhengmaBtn(){
      if(this.tel == ''){
        this.$toast('请输入手机号');  
        return false
      }
      let data = {
          type:'changemobile',
          mobile:this.tel
      }
      this.axios.post('https://api.wodelan.com/api/user/sendcaptcha',data).then(res =>{
          console.log(res)
          if(res.data.code == 1){
              this.$toast(res.data.msg);  
              this.yanzhengmaList = res.data.data
              this.zhaohuipsd = false
              this.zhaohuiTime = 60
              var auth_timetimer  = setInterval(() =>{
                  this.zhaohuiTime --;
                  if(this.zhaohuiTime <= 0){
                      this.zhaohuipsd = true;
                      clearInterval(auth_timetimer)
                  }
              },1000)   
          }else{
              this.$toast(res.data.msg);
          }
      })  
    },
    clickId(id) {
      console.log(id);
      this.tabId = id;
      if(id == 4){
        this.getCoupponList(0)
      }
      if(id == 7){
        localStorage.removeItem('user_id')
        localStorage.removeItem('username')
        localStorage.removeItem('password')
        this.$router.push({path:'/'})
      }
    },
    mywatch(id) {
      this.watchid = id;
    },
    watchXiangqing(id) {
      this.$router.push({ path: "./orderDetails", query: { id: id } });
    },
    changeAddress(item, index) {
      if (item.isDefault) {
        return;
      } else {
        this.addressList.map((sitem, aIndex) => {
          if (index == aIndex) {
            sitem.isDefault = true;
          } else {
            sitem.isDefault = false;
          }
        });
      }
 
    },
    //添加新地址
    handleAdd() {
      this.$router.push("/address-detail");
    },
    //地址详情
    goDetail(item) {
      console.log(item);
      this.$router.push("/address-detail");
    },
    onChange(value) {
      this.gender = value;
    },
    onConfirm() {
      if (!this.form.gender) {
        this.form.gender = this.genders[0];
      }
      this.showGender = false;
    },
    openBirth() {
      this.showBirth = true;
    },
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      console.log(year,month,day)
      this.birthday = year + "-" + month + "-" + day ;
      return year + "-" + month + "-" + day ;
    },
    onConfirmBirth(value) {
      this.form.birth = this.timeFormat(value);
      this.showBirth = false;
    },
    handleDelete(id) {
      console.log(id);
      let data = {
        id:id
      }
      this.axios.post('https://api.wodelan.com/api/index/addressDelete',data).then(res =>{
        console.log(res)
        if(res.data.code == 1){
          this.$toast(res.data.msg);
          setTimeout(() => {
            history.go(0)
            this.tabId = 3
          }, 1000);
        }else{
          this.$toast(res.data.msg);
        }
      })
    },
    //获取验证码
    getCode() {},
    //保存
    save() {
      console.log('点击保存',this.birthday)
      let data = {
        user_id: localStorage.getItem('user_id'),
        username : this.name,
        nickname : this.name,
        birthday : this.birthday,
        gender: this.genderRow,
        mobile:this.tel,
        captcha:this.code
      }
      this.axios.post('https://api.wodelan.com/api/user/profile',data).then(res =>{
        console.log(res)
        if(res.data.code == 1){
             if(res.data.code == 1){
                this.$toast(res.data.msg);
                setTimeout(() => {
                  history.go(0)
                  // this.tabId = 3
                }, 1000);
             }
        }else{
             this.$toast(res.data.msg);
        }
    })
    },
    weixiuDetails(){
      let data = {
          user_id : localStorage.getItem('user_id'),
          type:'',
          page:1,
          rows:40,
      }
      this.axios.post('https://api.wodelan.com/api/index/maintenanceList',data).then(res =>{
          console.log(res)
          console.log(JSON.parse(res.data.data))
          this.maintainList = JSON.parse(res.data.data)
      })
    },
    handleTab(id){
      this.curIndex = id
      console.log(this.curIndex)
      if(id == 0){
          let data = {
              user_id : localStorage.getItem('user_id'),
              type:'',
              page:1,
              rows:40,
          }
          this.axios.post('https://api.wodelan.com/api/index/maintenanceList',data).then(res =>{
              console.log(res)
              console.log(JSON.parse(res.data.data))
              this.maintainList = JSON.parse(res.data.data)
          })
      }else if(id == 1){
          let data = {
              user_id : localStorage.getItem('user_id'),
              type:1,
              page:1,
              rows:40,
          }
          this.axios.post('https://api.wodelan.com/api/index/maintenanceList',data).then(res =>{
              console.log(res)
              console.log(JSON.parse(res.data.data))
              this.maintainList = JSON.parse(res.data.data)
          })
      }else if(id == 2){
          let data = {
              user_id : localStorage.getItem('user_id'),
              type:2,
              page:1,
              rows:40,
          }
          this.axios.post('https://api.wodelan.com/api/index/maintenanceList',data).then(res =>{
              console.log(res)
              console.log(JSON.parse(res.data.data))
              this.maintainList = JSON.parse(res.data.data)
          })
      }else if(id == 3){
          let data = {
              user_id : localStorage.getItem('user_id'),
              type:3,
              page:1,
              rows:40,
          }
          this.axios.post('https://api.wodelan.com/api/index/maintenanceList',data).then(res =>{
              console.log(res)
              console.log(JSON.parse(res.data.data))
              this.maintainList = JSON.parse(res.data.data)
          })  
      }
  },
  linktoPay(item){
    console.log(item)
    var wqw = JSON.stringify(item)
    localStorage.setItem('addDetails',wqw)
    if(this.ispay == 1){
      this.$router.push({path:'../confirm-order'})
    }
  },
  // 支付
  gopay(item){
    console.log('维修保养订单信息',item);
    console.log('维修保养订单信息id',item.id);
    this.m_id = item.id
    this.weixiu_price = item.price
    this.coupon_price = item.price

    let type = ''
    if(item.maintenance_id == '维修'){
        type = 2
    }else if(item.maintenance_id == '保养'){
        type = 3
    }
    this.getCoupponList_pay(type)

    // if(localStorage.getItem('openId')){
    //     this.method = 'mp'
    //     this.openId = localStorage.getItem('openId')
    // }else{
    //     this.method = 'wap'
    //     this.openId = ''
    // }
    // let data = {
    //   method:this.method,
    //   openid:this.openId,
    //   m_id:item.id,
    // }
    // console.log('支付提交信息',data);
    // this.axios.post('https://api.wodelan.com/api/index/maintenancePay',data).then(res =>{
    //   console.log(res)
    //   this.pay = JSON.parse(res.data.data)
    //   if(res.data.code == 1){
    //     this.onBridgeReady()
    //   }else{
    //     this.$toast(res.data.msg);
    //   }
    // })
  },
  //弹出框立即支付
  payNow(){
    if(localStorage.getItem('openId')){
        this.method = 'mp'
        this.openId = localStorage.getItem('openId')
    }else{
        this.method = 'wap'
        this.openId = ''
    }
    let data = {
      method:this.method,
      openid:this.openId,
      m_id:this.m_id,
      amount:this.coupon_price,
      user_coupon_id:this.user_coupon_id
    }
    console.log('支付提交信息',data);
    this.axios.post('https://api.wodelan.com/api/index/maintenancePay',data).then(res =>{
      console.log(res)
      this.pay = JSON.parse(res.data.data)
      if(res.data.code == 1){
        // this.onBridgeReady()

        if(data.amount == 0){
            this.$router.push('/pay-success')
        }else{
            this.onBridgeReady()
        }
      }else{
        this.$toast(res.data.msg);
      }
    })
  },
  onBridgeReady(){
    console.log(this.pay)

    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          "appId":this.pay.pay.appId,     //公众号ID，由商户传入     
          "timeStamp":this.pay.pay.timeStamp,         //时间戳，自1970年以来的秒数     
          "nonceStr":this.pay.pay.nonceStr, //随机串     
          "package":this.pay.pay.package,     
          "signType":this.pay.pay.signType,         //微信签名方式：     
          "paySign":this.pay.pay.paySign //微信签名 
        },
        function(res){
          console.log(res)
        if(res.err_msg == "get_brand_wcpay_request:ok" ){
        // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            console.log('成功')  
            window.location.href = 'https://wdlh5.mb.shizuyx.com/pay-success?imgUrl=' +this.baseUrl
            // this.$router.push({path:'./pay-success'})
        } else{
          console.log('失败')
        }
    }); 
  },
  // 继续支付
  continuePay(id){
    if(localStorage.getItem('openId')){
      this.method = 'mp'
      this.openId = localStorage.getItem('openId')
    }else{
      this.method = 'wap'
      this.openId = ''
    }  
    let data = {
      method:this.method,
      openid:this.openId,
      order_id:id, 
      type : 1
    }
    this.axios.post('https://api.wodelan.com/api/index/tobe_pay',data).then(res =>{
      console.log(res)
      this.pay = JSON.parse(res.data.data)
      if(res.data.code == 1){
        // this.onBridgeReady()

        if(data.amount == 0){
            this.$router.push('/pay-success')
        }else{
            this.onBridgeReady()
        }
      }else{
        this.$toast(res.data.msg);
      }
    })
  },
  morenBtn(id){
    console.log('123')
      this.$dialog.alert({
         title: "提示", //加上标题
         message: "是否设为默认地址", //改变弹出框的内容
         showCancelButton: true //展示取水按钮
      })
      .then(() => { //点击确认按钮后的调用
        let data = {
          id : id,
          user_id : localStorage.getItem("user_id")
        }
        this.axios.post('https://api.wodelan.com/api/index/addressDefault',data).then(res =>{
          console.log(res)
          if(res.data.code == 1){
             this.$toast('设置成功');
             history.go(0)
            // this.$router.push({path:'./myContent',query:{addressId:id}})
             this.tabId = 3
          }
        })
      })
      .catch(() => { //点击取消按钮后的调用
          console.log("点击了取消按钮噢")
          history.go(0)
          return false
      })

  },
  // 编辑地址
  editaddress(item){
    console.log(item)
    var wqw = JSON.stringify(item)
    this.$router.push({path:"./address-detail",query:{item:wqw}})
  },
    // handleTab(i) {
    //   this.curIndex = i;
    // },
  },
};
</script>
<style lang="scss">
    .couponQH_max{
      display: flex;
      justify-content: space-between;
      text-align: center;
      color: #333;
      font-size: 14px;
      line-height: 30px;
      margin-bottom: 30px;
    }
    .couponQH{
      padding: 6px 15px;
      background: rgba(240, 240, 240, 0.39);
    }
    .couponActive{
      background: #000;
      color: #fff;
    }
    .popMaxCoupon{
      top: 0;
      position: fixed;
      z-index: 10000;
      background: #fff;
      width: 100%;
      height: 100vh;
      overflow-y: auto;
    }
    .couponPop{
        position: relative;
        margin-top: 30px;
    }
    .coupon{
        width: 80vw;
        height: 118px;
        background: rgba(255, 255, 255, 0.39);
        border: 1px solid #333333;
        margin: 0 auto;
        margin-bottom: 22px;
        padding: 13px 12px 0 12px;
        cursor: pointer;
        .couponCho{
            display: none;
        }
        img{
            width: 19px;
            margin-right: 5px;
        }
        .couponTitle{
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px dashed #707070;
            div{
              font-size: 14px;
            }
        }
        .couponContent{
            font-size: 12px;
        }
        .couponPrice{
            margin-top: 8px;
            font-size: 28px;
            span{
                font-size: 16px;
            }
        }
    }
    .nocoupon{
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.39);
        border: 1px solid #333333;
    }
    .couponActive2{
        border: 1px solid #D3B67D;
        position: relative;
        overflow: hidden;
        .couponCho{
            display: block;
        }
        .couponCho{
            position: absolute;
            top: -28px;
            right: -28px;
            width: 56px;
            height: 56px;
            background: rgba(211, 182, 125, 1);
            transform: rotate(45deg);
            span{
                display: inline-block;
                transform: rotate(-45deg);
                position: absolute;
                bottom: 0;
                left: 20px;
                color: #fff;
            }
        }
    }
    .bottom{
        position: fixed;
        bottom: 0;
        background: #fff;
        width: 100vw;
        height: 60px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.39);
        display: flex;
        justify-content: space-between;
        .price{
            font-size: 14px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            span{
                font-weight: bold;
            }
            .price2{
                font-size: 10px;
                text-decoration: line-through;
                position:relative;
                top: 2px;
                transform: scale(0.8);
            }
        }
        .payNow{
            width: 180px;
            background: #000;
            color: #fff;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .coupon_sx{
        opacity: 0.5;
    }
.topTab {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #f0f0f0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  -webkit-overflow-scrolling: touch;
  .tabList {
    font-size: 3.3333vw;
    margin-right: 4vw;
    margin-left: 4vw;
    line-height: 50px;
  }
  .tabListActvie {
    font-weight: bold;
  }
}
.topTab::-webkit-scrollbar {
  display: none;
}
// 我的账户
.my {
  width: 91.6vw;
  margin: 0 auto;
  margin-top: 6.5333vw;
  .my_title {
    font-size: 6.5333vw;
  }
  .my_phone {
    font-size: 3.7333vw;
    margin-top: 3.0667vw;
  }
  .my_text {
    font-size: 3.3333vw;
    color: #5c5c5c;
    margin-top: 0.5333vw;
    line-height: 4.6667vw;
  }
  .my_ddxx {
    width: 100%;
    height: 12.4vw;
    background: #f0f0f0;
    margin-top: 6.5333vw;
    .my_ddxx_text {
      font-size: 3.7333vw;
      color: #333333;
      padding-left: 4.9333vw;
      line-height: 12.4vw;
      font-weight: bold;
    }
  }
  .orderList {
    border-bottom: 0.1333vw solid #f0f0f0;
    display: flex;
    margin-top: 4.2667vw;
    padding-bottom: 4.2667vw;
    align-items: center;
    .order_right {
      display: flex;
      flex-wrap: wrap;
      width: 83.8667vw;
      .order_id {
        width: 30.3333vw;
      }
      .goods_id {
        width:47.333vw;
      }
      .order_id_text {
        font-size: 3.3333vw;
        font-weight: bold;
        color: #666666;
        margin-top: 4.9333vw;
      }
      .order_id_list {
        font-size: 3.333vw;
        color: #000000;
        font-weight: bold;
        margin-top: 0.8vw;
      }
      .time_text {
        font-size: 3.3333vw;
        font-weight: bold;
        color: #666666;
        margin-top: 4.9333vw;
        margin-left: 2.6667vw;
      }
      .time_list {
        font-size: 3.333vw;
        color: #000000;
        font-weight: bold;
        margin-top: 0.8vw;
        margin-left: 2.6667vw;
      }
    }
    .order_left{
      width: 9.6667vw;
    }
  }
  .mymessage {
    display: flex;
    justify-content: space-between;
    height: 18.6667vw;
    line-height: 18.6667vw;
    .myPhone {
      font-size: 3.3333vw;
      color: #000000;
    }
    .mymessageBtn {
      font-size: 3.3333vw;
      color: #333333;
    }
  }
}
.mywatch {
  width: 94.1333vw;
  margin: 0 auto;
  margin-top: 4.9333vw;
}
.mywatch_tab {
  display: flex;
  .cpdd {
    width: 28.2667vw;
    height: 10vw;
    background: #f0f0f0;
    text-align: center;
    font-size: #000000;
    line-height: 10vw;
    font-size: 3.3333vw;
    margin-right: 4.2667vw;
  }
  .cpddActive {
    background: #000000;
    color: #ffffff;
  }
  .dzjdRow {
    width: 100%;
    height: 58.1333vw;
    background: #f6f6f6;
  }
}
.dzjdRow {
  margin-top: 32px;
  width: 100%;
  height: 58.1333vw;
  background: #f6f6f6;
  .dz_top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    height: 10.5333vw;
    width: 90.9333vw;
    margin: 0 auto;
    align-items: center;
    .ddh {
      font-size: 3.3333vw;
      margin-left: 3.4667vw;
      color: #666666;
      span {
        color: #000000;
      }
    }
    .status {
      font-size: 3.3333vw;
      color: #000000;
      margin-right: 1.8667vw;
    }
  }
  .watchContent {
    width: 86.1333vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .watchImg {
      width: 21.7333vw;
      height: 21.7333vw;
      margin-top: 2.8vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .watchDetails {
      width: 60.4vw;
      margin-top: 2.8vw;
      .watchDetailsList {
        display: flex;
        justify-content: space-between;
        .watchDetailsRight {
          font-size: 3.3333vw;
          font-weight: bold;
          color: #333333;
          line-height: 4.6667vw;
        }
        .watchDetailsLeft {
          font-size: 3.3333vw;
          font-weight: bold;
          color: #333333;
          line-height: 4.6667vw;
        }
      }
    }
  }
  .watchTime {
    width: 86.1333vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 2.8vw;
    .timeRight {
      font-size: 3.3333vw;
      color: #666666;
      span {
        color: #000000;
      }
    }
    .timeLeft {
      font-size: 3.3333vw;
      color: #666666;
      span {
        color: #000000;
        font-weight: bold;
      }
    }
  }
  .footerBtn {
    width: 96.1333vw;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    margin-top: 5.2vw;
    .cancel {
      font-size: 3.3333vw;
      color: #333333;
      height: 7.7333vw;
      line-height: 7.7333vw;
      text-align: center;
      margin-right: 2.5333vw;
    }
    .pay {
      width: 22.1333vw;
      height: 7.7333vw;
      line-height: 7.7333vw;
      color: #ffffff;
      font-size: 3.3333vw;
      text-align: center;
      background: #000000;
    }
  }
}
.giveaddress {
  padding: 10px 12px;
  .address-list {
    overflow: hidden;
    .address-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: rgba(246, 246, 246, 0.39);
      padding: 14px 18px 18px;
      box-sizing: border-box;
      margin-bottom: 15px;
      .item-tp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info {
          display: flex;
          flex-direction: column;
          max-width: 270px;
          span {
            font-size: 16px;
            line-height: 32px;
            color: #333333;
          }
          .address-detail {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .van-badge__wrapper {
          font-size: 14px;
        }
      }
      .item-bt {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .ischeck {
          display: flex;
          align-items: center;
          .change {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            border: 1px solid #707070;
            margin-right: 6px;
            .checked {
              width: 10px;
              height: 10px;
              background: #333;
            }
          }
        }
      }
    }
    .add-address {
      height: 104px;
      line-height: 104px;
      text-align: center;
      font-size: 14px;
      color: #333;
      border: 1px solid #f6f6f6;
    }
  }
}
.accountinfo {
  padding: 18px;
  .account-wrap {
    .van-form {
      .van-field {
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .van-field__label {
          width: 100%;
          font-size: 14px;
          color: #333;
          margin-bottom: 8px;
        }
        .van-field__value {
          .van-field__body {
            .van-field__control {
              height: 48px;
              border: 1px solid #f0f0f0;
              padding: 0 8px;
            }
          }
        }
        &::after {
          display: none;
        }
      }
      .select-wrap {
        display: flex;
        align-items: center;
        position: relative;
        .van-icon {
          position: absolute;
          right: 15px;
          top: 48px;
          font-size: 20px;
        }
        .van-button {
          margin-bottom: 20px;
          height: 48px;
          font-size: 14px;
        }
      }
      .van-button {
        background: #000000;
        color: #ffffff;
        width: 270px;
        margin: auto;
        height: 44px;
        border-color: #000;
        font-size: 18px;
        margin-bottom: 13px;
      }
    }
  }
}
.repair {
  padding: 18px;
  .repair-tab {
    display: flex;
    margin-bottom: 18px;
    .tab-item {
      width: 23.3%;
      height: 43px;
      line-height: 43px;
      text-align: center;
      background: rgba(240, 240, 240, 0.39);
      font-size: 14px;
      color: #333;
      margin-right: 15px;
    }
    .active-tab-item {
      background: #000000;
      color: #ffffff;
    }
  }
  .repair-info {
    background: rgba(246, 246, 246, 0.39);
    margin-bottom: 30px;
    font-size: 14px;
    .order-info {
      margin-bottom: 5.3333vw;
      color: #666666;
      padding: 10px 12px 13px;
      border-bottom: 4vw solid #ffffff;

      .number-time,
      .order-status {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        b {
          font-weight: normal;
          color: #000000;
          padding-left: 4px;
        }
      }
      .order-status {
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 11px;
      }
      .name-wrap {
        font-size: 12px;
        padding: 8px 0 0;
        overflow: hidden;
        .name {
          display: flex;
          margin-bottom: 6px;
          .name-lt,
          .name-gt {
            flex: 1;
            text-align: left;
            color: #333;
            font-size: 12px;
            span {
              padding-left: 4px;
            }
          }
        }
        .address {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          margin-top: 6px;
          border-bottom: 1px solid #f0f0f0;
          padding-bottom: 11px;
        }
      }
      .remark-text {
        padding-top: 10px;
        font-size: 12px;
        .remark-title {
          padding-bottom: 5px;

          color: #333;
          font-weight: bold;
        }
        .remark-content {
          line-height: 17px;
          margin-bottom: 12px;
        }
        .remark-img {
          display: flex;
          flex-wrap: wrap;
          .img-item {
            width: 14%;
            height: 43px;
            margin: 0 2.6% 2.6% 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .repair-btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          margin-top: 17px;
          b {
            color: #000000;
            font-weight: bold;
            padding-left: 4px;
          }
          .van-button {
            width: 40%;
            height: 33px;
            background: #000000;
            color: #ffffff;
            border-color: #000000;
            font-size: 14px;
          }
        }
      }
    }
  }
  .add-repair {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    color: #ffffff;
    font-size: 16px;
    .van-icon {
      font-size: 20px;
      margin-right: 4px;
    }
  }
}
.passwordYanzhengmaBtn{
  font-size: 3.33vw;
  margin-left: 2vw;
}
.select_row{
  width: 91.0667vw;
  margin: 0 auto;
}
.select_name{
  font-size: 3.3333vw;
  color: #333333;
  // margin-top: 5.3333vw;
}
.selectList{
  width: 91.0667vw;
  margin-top: 2.4vw;
  height: 12.4vw;
  border: 0.2666vw solid #F0F0F0;
  border-radius: 0.6667vw;
  margin-bottom: 5.3333vw;
  outline: none;
}
</style>