<template>
  <div class="repair-maintain homeTop">
    <div class="repair-img">
      <img src="../assets/image/repair-maintain.png" alt="" />
    </div>
    <!-- 维修中心（办理邮寄维修业务） -->
    <div class="repair-center">
      <div class="title">{{totalList.name}}</div>
      <span class="text">
        {{totalList.address}}</span
      >
    </div>
    <!-- 咨询热线 -->
    <div class="repair-center">
      <div class="title">咨询热线</div>
      <span class="text"> {{totalList.phone}}</span>
    </div>
    <div class="notice">
      <div class="notes-text">
        注：{{totalList.remark}}
      </div>
      <div><van-button type="primary" @click="linktoBaoyang()">我要保养</van-button></div>
    </div>
    <div class="repair-note">保养内容</div>
    <div class="content-list">
      <!-- 表头 -->
      <div class="content-item head-item">
        <span class="cell cell1">服务事项</span>
        <span class="cell cell2">完全保养</span>
        <span class="cell cell3">部分保养</span>
      </div>
      <div class="content-item" v-for="(item, index) in liuchengList" :key="index">
        <span class="cell cell1">{{ item.name }}</span>
        <span class="cell cell2" style="margin-right:7vw;">
          <img
            v-if="item.complete == 1"
            src="../assets/image/support.png"
            alt=""
          />
        </span>
        <span class="cell cell3" >
          <img v-if="item.part == 1" src="../assets/image/support.png" alt="" />
        </span>
      </div>
    </div>
    <div class="repair-note">保养流程</div>
    <div class="process-wrap">
      <img :src="liucheng[0].thumb" alt="" />
      <div class="process-name">
        <span>电话咨询</span>
        <span>发起申请寄出产品</span>
        <span>收到产品评估费用</span>
        <span>支付费用保养完成</span>
        <span>回寄客户</span>
      </div>
    </div>
    <div class="repair-note">服务品牌</div>
    <div class="brand-list">
      <!-- <img src="../assets/image/brand1.png" alt="" />
      <img src="../assets/image/brand2.png" alt="" /> -->
      <img src="../assets/image/fwpp.png" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: "RepairMaintain",
  data() {
    return {
      list: [
        { name: "预检手表并报价", isCompletely: true, isPart: true },
        { name: "预检手表并报价", isCompletely: true, isPart: true },
        { name: "预检手表并报价", isCompletely: true, isPart: true },
        { name: "预检手表并报价", isCompletely: true, isPart: true },
        { name: "预检手表并报价", isCompletely: true, isPart: true },
        { name: "预检手表并报价", isCompletely: true, isPart: true },
      ],
      isdui:1,
      totalList:'',
      liuchengList:'',
      liucheng:'',
    };
  },
  created() {
    this.maintenancecenter()
    this.maintenanceprocess()
    this.maintenancecontent()
  },
  methods: {
    maintenancecenter(){
      let data ={
          type : 2
      }
      this.axios.post('https://api.wodelan.com/api/index/maintenancecenter',data).then(res => {
          console.log(res)
          console.log(JSON.parse(res.data.data))
          this.totalList = JSON.parse(res.data.data)
      })
    },
    maintenanceprocess(){
      let data = {
          type : 2
      }
      this.axios.post('https://api.wodelan.com/api/index/maintenanceprocess',data).then(res =>{
          console.log(res)
          console.log(JSON.parse(res.data.data))
          this.liucheng = JSON.parse(res.data.data)
          console.log(this.liucheng[0].thumb)
      })
    },
    maintenancecontent(){
      let data = {
          type : 2
      }
      this.axios.post('https://api.wodelan.com/api/index/maintenancecontent',data).then(res =>{
          console.log(res)
          console.log(JSON.parse(res.data.data))
          this.liuchengList = JSON.parse(res.data.data)
      })
    },
    linktoBaoyang(){
      if(!localStorage.getItem('user_id')){
        this.$toast('请登录');
        this.$router.push({path:'/login'})
      }else{
        this.$router.push('/add-repair')
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.repair-maintain {
  .repair-img {
    width: 100%;
    height: 145px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .repair-center {
    margin-top: 30px;
    padding: 0 12px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }
    .text {
      font-size: 12px;
      color: #333;
    }
  }
  .notice {
    padding: 0 12px;
    margin: 36px 0 16px;
    .notes-text {
      font-size: 12px;
      color: #d3b67d;
      margin-bottom: 10px;
    }
    .van-button {
      width: 149px;
      height: 41px;
      line-height: 41px;
      background: #d3b67d;
      border-color: #d3b67d;
      .van-button__text {
        color: #ffffff;
      }
      //   color: #ffffff !impo;
    }
  }
  .repair-note {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background: #000000;
  }
  .content-list {
    .content-item {
      display: flex;
      height: 41px;
      border-bottom: 1px solid #f6f6f6;
      align-items: center;
      justify-content: space-between;
      padding: 0 22px;
      .cell {
        font-size: 12px;
        font-weight: bold;
        color: #333;
      }
      .cell1 {
        flex: 1;
        margin-right: 15px;
      }
      .cell2,
      .cell3 {
        min-width: 25px;
      }
      .cell3 {
        margin-left: 89px;
      }
    }
    .head-item {
      height: 52px;
    }
  }
  .process-wrap {
    display: flex;
    flex-direction: column;
    padding: 17px 17px 35px;
    img{
      width: 88.8vw;
      height: 11.4667vw;
    }
    .process-name {
      display: flex;
      justify-content: space-between;
      span {
        width: 48px;
        font-size: 12px;
        color: #575757;
        margin-top: 8px;
      }
    }
  }
  .brand-list {
    padding: 17px 17px 35px;

    img {
      width: 100%;
    }
  }
}

</style>
