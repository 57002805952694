<template>
  <div class="register-success">
    <van-nav-bar title="注册成功" left-arrow @click-left="onClickLeft" />
    <div class="wrap">
      <div class="icon-wrap">
        <img src="../assets/image/register-success.png" alt="" />
        <span class="desc">注册成功</span>
      </div>
      <div class="remark">
        恭喜{{tel}}用户，账号注册成功，现在可以在会员中心更新您的个人信息以及查看您已下单的订单进度
      </div>
      <div class="back" @click="goHome()">返回首页</div>
      <div class="btn-wrap">
        <van-button type="primary" @click="goLogin()">立即登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterSuccess",
  data() {
    return {
      tel:'',
    };
  },
  created() {
    this.$emit("header", false);
    this.tel = this.$route.query.tel
  },
  methods: {
    onClickLeft() {
      history.go(-1)
    },
    goHome(){
      this.$router.push({path:'./Home'})
    },
    goLogin(){
      this.$router.push({path:'./login'})
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.register-success {
  .wrap {
    padding: 43px 20px;
    .icon-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .desc {
        font-size: 18px;
        color: #000000;
        font-weight: bold;
        margin-top: 10px;
      }
    }
    .remark {
      width: 334px;
      margin: 10px auto;
      text-align: center;
      line-height: 22px;
      font-size: 16px;
    }
    .back {
      margin: 59px 0 22px;
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-weight: bold;
    }

    .btn-wrap {
      display: flex;
      .van-button {
        width: 100%;
        background: #000000;
        color: #ffffff;
        height: 55px;
        border-color: #000;
        font-size: 18px;
        margin-bottom: 13px;
      }
    }
  }
}
</style>
