<template>
  <div class="address-detail">
    <van-nav-bar title="新增维保申请" left-arrow @click-left="onClickLeft" />
    <div class="address-wrap">
      <van-form ref="form">
        <!-- 维修品牌 -->
        <van-field
          label="维修品牌"
          v-model="brand"
          name="brand"
        ></van-field>

        <!-- 维修/保养/配件加工 -->
        <div class="select_row">
          <div class="select_name">维修/保养/配件加工</div>
          <select name="" id="" class="selectList" v-model="weixiuRow">
            <option  v-for="(item,index) in tabList" :key="index" :value="item.id">{{item.name}}</option>
          </select>
        </div>
        <!-- 快递公司 -->
        <van-field
          label="快递公司"
          v-model="company"
          name="company"
        ></van-field>
        <!-- 快递单号 -->
        <div class="select-wrap">
          <van-field
            v-model="odd_numbers"
            name="odd_numbers"
            label="快递单号"
            style="width: 100%"
          />
          <van-button @click="scanning" style="width: 30%" type="primary" block>扫描单号</van-button>
        </div>
        <!-- 维修/保养完成是否需要邮寄 -->
        <div class="select_row">
          <div class="select_name">维修/保养完成是否需要邮寄</div>
          <select name="" id="" class="selectList" v-model="youjiIs" >
            <option  v-for="(item,index) in youjiList" :key="index" :value="item.id">{{item.name}}</option>
          </select>
        </div>
        <!-- 收件人 -->
        <van-field
          label="收件人"
          v-model="receiving"
          name="receiving"
        ></van-field>
        <!-- 收件电话 -->
        <van-field
          label="收件电话"
          v-model="receiving_tel"
          name="receiving_tel"
        ></van-field>
        <!-- 收件地址 -->
        <van-field
          label="收件地址"
          v-model="receiving_ads"
          name="receiving_ads"
        ></van-field>
        <!-- 备注说明 -->
        <van-field
          class="remark-ipt"
          label="备注说明"
          v-model="remark"
          name="remark"
        ></van-field>
        <!-- 产品图片（最多上传9张图） -->
        <!-- <div class="upload">
          <div class="upload-notice">产品图片（最多上传9张图）</div>
          <van-uploader v-model="urls" />
        </div> -->
         <div class="upload">
          <div class="upload-notice">产品图片（最多上传9张图）</div>
          <van-uploader 
          :after-read="afterRead" 
          :upload-text="'上传图片'" 
          :before-read="beforeRead" 
          :before-delete="deleteImg"
          v-model="urls"
          />
         </div>

        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker
            :columns="curColumns"
            @change="onChange"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-button type="primary" block @click="submit">提交</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
// import BarScan from '/myProject/wdlWap/wodelan_h5/src/components/scan.vue';
export default {
  name: "AddRepair",
  // components: { BarScan },
  data() {
    return {
      form: {},
      showPicker: false,
      machinings: ["钟表维修", "钟表保养"],
      isMail: ["是", "否"],
      curColumns: [],
      curPicker: 0,
      urls: [],
      imgList:[],
      tabList:[
        {
            id:2,
            name:'维修'
        },{
            id:3,
            name:'保养'
        },{
            id:4,
            name:'配件加工'
        }
      ],
      youjiList:[
        {
            id:'1',
            name:'是'
        },{
            id:'0',
            name:'否'
        }
      ],
      youjiIs:'',
      weixiuRow:'',
      brand:'',
      company:'',
      odd_numbers:'',
      receiving:'',
      receiving_tel:'',
      receiving_ads:'',
      remark:'',
      scancode:false,//控制是否使用扫一扫功能
      result: '',//扫码结果信息
    };
  },
  created() {
    this.$emit("header", false);

    console.log('扫描结果====',this.$route.params.res)
    if(this.$route.params.res){
      var  res = this.$route.params.res
      this.odd_numbers = res
    }
    if(this.$route.params.datas){
      let data = JSON.parse(this.$route.params.datas)
      this.brand = data.brand
      this.weixiuRow = data.weixiuRow
      this.company = data.company
      this.youjiIs = data.youjiIs
      this.receiving = data.receiving
      this.receiving_tel = data.receiving_tel
      this.receiving_ads = data.receiving_ads
      this.remark = data.remark
      this.urls = data.urls
    }
  },
  methods: {
    onClickLeft() {
      // this.$router.back();
      this.$router.push('/myContent')
    },
    onChange(value) {
      console.log(value)
      if (this.curPicker == 1) {
        this.form.machining = value;
      }
      if (this.curPicker == 2) {
        this.form.is_mail = value;
      }
    },
    onConfirm() {
      if (!this.form.machining && this.curPicker == 1) {
        this.form.machining = this.machinings[0];
      }
      if (!this.form.is_mail && this.curPicker == 2) {
        this.form.is_mail = this.isMail[0];
      }
      this.showPicker = false;
    },
    openPicker(type) {
      this.curPicker = type;
      if (type == 1) {
        this.curColumns = this.machinings;
      }
      if (type == 2) {
        this.curColumns = this.isMail;
      }
      this.showPicker = true;
    },
    // 扫描单号
    scanning() {
      let data = {
        brand : this.brand,
        weixiuRow : this.weixiuRow,
        company : this.company,
        youjiIs : this.youjiIs,
        receiving : this.receiving,
        receiving_tel : this.receiving_tel,
        receiving_ads : this.receiving_ads,
        remark : this.remark,
        urls : this.urls
      }
      let datas = JSON.stringify(data)
      // this.$router.push('/scanCodePage')
      this.$router.push({ name: 'scanCodePage', params: { datas }})
    },



    submit() {
      // this.$refs.form
      //   .validate()
      //   .then(() => {
      //     console.log(this.form);
      //   })
      //   .catch(() => {});
      let data = {
        brand_id : this.brand,
        user_id : localStorage.getItem('user_id'),
        maintenance_id:this.weixiuRow,
        courier_company:this.company,
        tracking_number:this.odd_numbers,
        recipient:this.receiving,
        phone:this.receiving_tel,
        address:this.receiving_ads,
        content:this.remark,
        thumb:this.imgList,
        is_delivery:this.youjiIs,
      }
      this.axios.post('https://api.wodelan.com/api/index/maintenanceUpdate',data).then(res =>{
        console.log(JSON.parse(res.data.data))
        console.log(res)
        if(res.data.code == 1){
           this.$toast(res.data.msg);
            setTimeout(() => {
              this.$router.push({path:'./myContent'})
            }, 1000);
        }else{
          this.$toast(res.data.msg);
        }
      })
    },
    afterRead (file) {
        this.uploadImg(file.file)
    },
    beforeRead (file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          this.$toast('请上传 jpg/png 格式图片');
              return false;
      }
      let isLt1M = file.size / 1024 / 1024 <= 1
      if (!isLt1M) {
          this.$toast('图片大小1M以内');
          return false
      }
      return true;
  },
    uploadImg (file) {
    // 创建form对象
      let formdata1 = new FormData();
      // 通过append向form对象添加数据,可以通过append继续添加数据
      //或formdata1.append('file',file);
      formdata1.append('file', file, file.name);
      //设置请求头
      let config = {
          headers:{
              'Content-Type':'multipart/form-data'
          }
      };  
      //this.axios 是因为在main.js写在vue实例里
      const axiosAjax = this.axios.create({
          timeout: 1000 * 60, //时间
          withCredentials: true //跨域携带cookie
      });
      axiosAjax.post('https://api.wodelan.com/api/index/upload',formdata1,config).then((res)=>{   //这里的url为后端接口
          this.imgList.push(res.data.data.url)
          console.log(this.imgList)
          //res 为接口返回值
      }).catch(() => {})
    },
    deleteImg(elIndex,file){
      console.log('删除')
      const i = this.imgList.findIndex((x) => x.pic === filePath);
      this.imgList.splice(i, 1);
      console.log(this.imgList);
      const filePath = file;
      let vm = this;
      //name.index代表图片的索引
      return (file, name) => {
        let fileIndex = name.index
        // console.log(fileIndex)
        vm.imgList[elIndex].splice(fileIndex, 1)
        vm.imgList[elIndex].splice(fileIndex, 1)
        
      }
            // const i = this.imgList.findIndex((x) => x.pic === filePath);
            // this.imgList.splice(i, 1);
            // console.log(this.imgList);
            // // app.productPicList.remove(file.name);
            // // const filePath = file.response.data.tmp_path;
            // console.log(file)
            // const filePath = file;
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.address-detail {
  .address-wrap {
    padding: 22px 18px;
    .van-form {
      .van-field {
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .van-field__label {
          width: 100%;
          font-size: 14px;
          color: #333;
          margin-bottom: 8px;
        }
        .van-field__value {
          .van-field__body {
            .van-field__control {
              height: 48px;
              border: 1px solid #f0f0f0;
              padding: 0 8px;
            }
          }
        }
        &::after {
          display: none;
        }
      }
      .remark-ipt {
        .van-field__value {
          .van-field__body {
            .van-field__control {
              height: 135px !important;
            }
          }
        }
      }
      .select-wrap {
        position: relative;
        display: flex;
        .van-icon {
          position: absolute;
          right: 15px;
          top: 48px;
          font-size: 20px;
        }
        .van-button {
          width: 32% !important;
          margin-bottom: 20px;
          height: 48px;
          font-size: 14px;
        }
      }
      .upload {
        padding: 2px 0 35px;
        .upload-notice {
          font-size: 14px;
          color: #333;
          margin-bottom: 8px;
        }
        .van-uploader {
          width: 100%;
          .van-uploader__wrapper {
            width: 100%;
            .van-uploader__preview,
            .van-uploader__upload {
              width: 23%;
              margin-right: 2%;
            }
          }
        }
      }
      .van-button {
        background: #000000;
        color: #ffffff;
        width: 270px;
        margin: auto;
        height: 44px;
        border-color: #000;
        font-size: 18px;
        margin-bottom: 13px;
      }
    }
  }
}
.select_row{
  width: 91.0667vw;
  margin: 0 auto;
}
.select_name{
  font-size: 3.3333vw;
  color: #333333;
  // margin-top: 5.3333vw;
}
.selectList{
  width: 91.0667vw;
  margin-top: 2.4vw;
  height: 12.4vw;
  border: 0.2666vw solid #F0F0F0;
  border-radius: 0.6667vw;
  margin-bottom: 5.3333vw;
  outline: none;
}

</style>
