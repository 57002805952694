<template>
    <div class="box homeTop">
        <div class="banner">
            <div class="name">{{aboutList.name}}</div>
            <div class="shijian">发布时间{{aboutList.createtime}}</div>
        </div>
        <div class="indexHtml">
            <div class="content" v-html="aboutList.content"></div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            id:'',
            aboutList:'',
        }
    },
    methods:{
        aboutDetails(){
            let data = {
                aid : this.id
            }
            this.axios.post('https://api.wodelan.com/api/index/index_article',data).then(res =>{
                console.log(res)
                this.aboutList = JSON.parse(res.data.data)
            })
        },
    },
    created(){
        this.id = this.$route.query.id
        this.aboutDetails()
    },
}
</script>
<style>
.banner{
    width: 100%;
    height: 21.0667vw;
    background: url('../assets/image/aboutUsBanner.png') no-repeat;
    background-size: 100% 100%;
}
.name{
    font-size: 5.6vw;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    padding-top: 4.6667vw;
}
.shijian{
    font-size: 2.1333vw;
    color: #ffffff;
    margin-top: 0.6667vw;
    text-align: center;
}
.content{
    width: 93.7333vw;
    margin: 0 auto;
    margin-top: 2.8vw;
}
.content img{
    width: 100% !important;
}
.content p{
    font-size: 2.8vw;
    color: #000000;
}
</style>