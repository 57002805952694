<template>
  <div v-if="$route.meta.keepAlive">
    <!-- 菜单 -->
    <!-- <div
      class="menu"
      :style="`background:${
        isBlack ? 'rgba(255, 255, 255, 0.39)' : '#000000'
      };box-shadow:${isBlack ? ' 0px 3px 6px rgba(0, 0, 0, 0.16)' : ''}`" -->
    <!-- > -->
    <div class="menu" style="background:rgba(255,255,255);box-shadow:0px 3px 6px rgba(0,0,0,0.16)">
      <router-link to="/Home">
        <img v-if="show == false"  class="logo2" src="../assets/image/logo-black2.png" alt="" />
      </router-link>
      
      
      <img
        v-if="show == false"
        class="logo"
        src="../assets/image/menu-black.png"
        alt=""
        @click="openMenu"
      />
    <div class="cross-wrap" v-else>
        <van-icon name="cross" @click="show = false" />
    </div>
    </div>
    <van-popup v-model:show="show" position="top">
      <div class="popup-wrap">
        <div class="cross-wrap">
          <van-icon name="cross" @click="show = false" />
        </div>
        <van-collapse v-model="activeNames" accordion>
          <van-collapse-item title="首页" name="1">
            <div class="children-list">
              <div class="children-item" @click="goHone()">首页</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="关于WODELAN" name="2">
            <div class="children-list">
              <div class="children-item" @click="linktoAbout()">关于WODELAN</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="新品预售" name="3">
            <div
              class="children-list"
              v-for="item in yushouTitle"
              :key="item.od"
            >
              <div class="children-item" @click="linktoYs(item.id)">
                {{ item.name }}
              </div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="维修保养" name="4">
            <div class="children-list">
              <div class="children-item" @click="linktoby(1)">维修中心</div>
              <div class="children-item" @click="linktoby(2)">保养中心</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="产品中心" name="5">
            <div class="children-list">
              <div class="children-item"  @click="linktoby(4)">腕表</div>
              <div class="children-item"  @click="linktoby(3)">工艺品</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="会员中心" name="6">
            <div class="children-list">
              <div class="children-item" @click="linktoby(5)">会员中心</div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "v-head",
  data() {
    return {
      show: false,
      activeNames: "1",
      scrollTop:'',
    };
  },
  props: {
    isBlack: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    openMenu() {
      this.show = true;
    },
    closeHead(){
      this.show = false
    },
    indexGoods() {
      this.axios
        .post("https://api.wodelan.com/api/index/indexGoods")
        .then((res) => {
          this.yushouTitle = JSON.parse(res.data.data);
          console.log(this.yushouTitle);
        });
    },
    linktoYs(id) {
      this.$router.push({ path: "../views/goods-detail", query: { id: id } });
      this.show = false;
    },
    linktoAbout(){
      this.$router.push({path:'../views/about-us'})
      this.show = false;
    },
    goHone(){
      this.$router.push({path:'../views/home'})
      this.show = false
    },
    linktoby(id){
      if(id == 1){
        this.$router.push({path:'../views/repair-maintain'})
        this.show = false
      }
      if(id == 2){
        this.$router.push({path:'../views/maintain'})
        this.show = false
      }
      if(id == 3){
        this.$router.push({path:'../views/product'})
        this.show = false
      }
      if(id == 4){
        this.$router.push({path:'../views/watch'})
        this.show = false
      }
      if(id == 5){
        this.$router.push({path:'../views//myContent/myContent'})
        this.show = false
      }
    },
    // scrollToTop(){
    //   this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   // console.log(this.scrollTop)
    //   if(this.scrollTop == 0){
    //     this.show = true
    //   }
    // },
  },
  mounted(){
    // window.addEventListener('scroll', this.scrollToTop)
  },
  created() {
    this.indexGoods();
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: 53px;
  padding: 0 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: calc(100% - 52px);
  z-index: 9999;
  left: 0;
  top: 0;
}
.van-popup {
  .popup-wrap {
    .cross-wrap {
      padding: 17px 30px 51px;
      display: flex;
      justify-content: flex-end;
    }
    .van-collapse {
      .van-collapse-item {
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            .children-list {
              .children-item {
                font-size: 12px;
                height: 30px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
.closeHead{
  width: 6.5333vw;
  height: 6.5333vw;
}
.logo2{
  width: 13.2vw;
  height: 6.9333vw;
}
.head-max{
  z-index: 9999 !important;
}
</style>
