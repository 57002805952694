<template>
  <div class="binding">
    <!-- <van-nav-bar title="绑定手机号" left-arrow @click-left="onClickLeft" /> -->
    <div class="wrap">
      <div class="title">绑定手机号</div>
      <div class="form-wrap">
        <div class="input-wrap">
          <div class="label-wrap">
            <span class="label">手机号</span>
          </div>
          <input type="text" v-model="tel" />
        </div>
        <div class="input-wrap">
          <div class="label-wrap">
            <span class="label">密码</span>
          </div>
          <input type="text" v-model="psd" />
        </div>
        <div class="input-wrap">
          <span class="label-wrap">
            <span class="label">手机验证码</span>
          </span>
          <div style="display: flex; align-items: center">
            <input
              type="text"
              style="width: 161px"
              v-model="verification"
            />
            <span style="font-size: 12px; flex: 1; text-align: center"
              v-show="zhaohuipsd"
              @click="yanzhengmaBtn()">获取手机验证码</span
            >
            <span class="passwordYanzhengmaBtn"  v-show="!zhaohuipsd">{{zhaohuiTime}}s后获取</span>
          </div>
        </div>

        <div class="input-wrap">
          <van-button type="primary" @click="bangding()">绑定</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BindingPhone",
  data() {
    return {
      form: {},
      zhaohuipsd:true,
      tel:"",
      yanzhengmaList:'',
      zhaohuiTime:0,
      psd:'',
      verification:'',
    };
  },
  created() {
    this.$emit("header", false);
  },
  methods: {
    onClickLeft() {
      history.go(-1)
    },
    yanzhengmaBtn(){
      if(this.tel == ''){
        this.$toast('请输入手机号');  
        return false
      }
      let data = {
          type:'bind',
          mobile:this.tel
      }
      this.axios.post('https://api.wodelan.com/api/user/sendcaptcha',data).then(res =>{
          console.log(res)
          if(res.data.code == 1){
              this.$toast(res.data.msg);  
              this.yanzhengmaList = res.data.data
              this.zhaohuipsd = false
              this.zhaohuiTime = 60
              var auth_timetimer  = setInterval(() =>{
                  this.zhaohuiTime --;
                  if(this.zhaohuiTime <= 0){
                      this.zhaohuipsd = true;
                      clearInterval(auth_timetimer)
                  }
              },1000)   
          }else{
              this.$toast(res.data.msg);
          }
      }) 
    },
    bangding(){
      let data = {
        openid : localStorage.getItem('openId'),
        // openid:'o3_xh6NiPzq0gj1bmZXZAIzZec58',
        mobile : this.tel,
        password : this.psd,
        code: this.verification,
      }
      this.axios.post('https://api.wodelan.com/api/index/bind',data).then(res =>{
        console.log(res)
        if(res.data.code == 1){
          localStorage.setItem('username',this.tel)
          localStorage.setItem('name',this.tel)
          localStorage.setItem('password',this.psd)
          localStorage.setItem('user_id',res.data.data.user_id)
          this.$toast(res.data.msg)
          setTimeout(() => {
            // this.$router.push({path:'./myContent/myContent'})
            const curr = localStorage.getItem('preRoute')
            let ids = curr.split("=")
            if (curr == null) {
              this.$router.push({path:'./myContent/myContent'})
            } else {
              this.$router.push({ path: curr , query: { id: ids[1] }});
            }
          }, 1000);
        }else{
          this.$toast(res.data.msg)
        }
      })
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.binding {
  .wrap {
    padding: 19px 13px;
    .title {
      font-size: 25px;
      font-weight: bold;
    }
    .form-wrap {
      margin-top: 35px;
      .input-wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 34px;
        .label-wrap {
          font-size: 16px;
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;
        }

        input {
          height: 50px;
          font-size: 16px;
          padding: 0 10px;
        }
      }
      .privacy {
        display: flex;
        align-items: center;
        margin: 18px 0;
        span {
          font-size: 12px;
          margin-left: 10px;
        }
      }
      .van-button {
        background: #000000;
        color: #ffffff;
        height: 55px;
        border-color: #000;
        font-size: 18px;
        margin-bottom: 13px;
      }
    }
  }
}
.passwordYanzhengmaBtn{
  font-size: 3.33vw;
  margin-left: 2vw;
}
</style>
