<template>
  <div class="about-us homeTop">
    <div class="about-img">
      <img :src="aboutList.thumb" alt="" />
    </div>
    <!-- 沃德兰·品不凡！ -->
    <div class="about-content">
      <div class="title">{{ aboutList.title1 }}</div>
      <div class="contentss">
        {{ aboutList.name }}
      </div>
    </div>
    <div class="about-img">
      <img :src="aboutList.brand_thumb" alt="" />
    </div>
    <!-- 心之所动·谋之所向 -->
    <div class="about-content">
      <div class="title">{{ aboutList.title2 }}</div>
      <div class="contentss">
        {{ aboutList.brand_content }}
      </div>
    </div>
    <div class="about-img">
      <img :src="aboutList.core_thumb" alt="" />
    </div>
    <!-- 匠心所成·如你所愿 -->
    <div class="about-content">
      <div class="title">{{ aboutList.title3 }}</div>
      <div class="contentss">
        {{ aboutList.core_content }}
      </div>
    </div>
    <!-- 任务介绍 -->
    <div class="character" v-for="(item,index) in manList" :key="index">
      <div class="character-img">
        <img :src="item.thumb" alt="">
      </div>
      <div class="character-desc" >
        <div class="character-name">
          <span class="line"></span>
          <span class="name">{{item.name}}</span>
        </div>
        <div class="character-text">
          {{item.content}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      manList: "",
      aboutList: "",
    };
  },
  created() {
    this.isBlack = true
    this.artisan();
    this.aboutUs();
  },
  methods: {
    artisan() {
      this.axios
        .post("https://api.wodelan.com/api/index/artisan")
        .then((res) => {
          console.log(res);
          console.log(JSON.parse(res.data.data));
          this.manList = JSON.parse(res.data.data);
        });
    },
    aboutUs() {
      this.axios
        .post("https://api.wodelan.com/api/index/index_aboutus")
        .then((res) => {
          console.log(res);
          console.log(JSON.parse(res.data.data));
          this.aboutList = JSON.parse(res.data.data);
        });
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.about-us {
  .about-img {
    width: 100%;
    margin-bottom: 41px;
    height: 114px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .about-content {
    padding: 0 22px;
    .title {
      padding-bottom: 8px;
      font-size: 20px;
      font-weight: bold;
      color: #000;
    }
    .contentss {
      font-size: 12px;
      line-height: 30px;
      color: #333;
      margin-bottom: 41px;
      letter-spacing: 2px;
    }
  }
  .character {
    .character-img {
      width: 100%;
      // height: 224px;
      // background: rgba(147, 147, 147, 0.39);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .character-desc {
      padding: 12px 22px 22px;
      .character-name {
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 18px;
          background: #d3b67d;
          opacity: 1;
          margin-top: 18px;
        }
        .name {
          font-size: 16px;
          color: #d3b67d;
          font-weight: bold;
          padding-left: 3px;
        }
      }
      .character-text {
        margin-top: 10px;
        font-size: 12px;
        line-height: 24px;
      }
    }
  }
}
</style>
