<template>
  <div class="forget">
    <van-nav-bar title="忘记密码" left-arrow @click-left="onClickLeft" />
    <div class="wrap">
      <div class="title">{{ isForget ? "找回密码" : "输入新密码" }}</div>
      <div class="form-wrap">
        <div class="input-wrap" v-if="isForget">
          <div class="label-wrap">
            <span class="label">手机号</span>
          </div>
          <input type="text" v-model="tel" />
        </div>
        <div class="input-wrap" v-else>
          <div class="label-wrap">
            <span class="label">新密码</span>
          </div>
          <input type="text" v-model="newPassword" />
        </div>
        <div class="input-wrap" v-if="isForget">
          <span class="label-wrap">
            <span class="label">手机验证码</span>
          </span>
          <div style="display: flex; align-items: center">
            <input
              type="text"
              style="width: 211px"
              v-model="verification"
            />
             <span style="font-size: 12px; flex: 1; text-align: center"
              v-show="zhaohuipsd"
              @click="yanzhengmaBtn()">获取手机验证码</span
            >
            <span class="passwordYanzhengmaBtn"  v-show="!zhaohuipsd">{{zhaohuiTime}}s后获取</span>
          </div>
        </div>
        <div class="input-wrap" v-else>
          <div class="label-wrap">
            <span class="label">确认新密码</span>
          </div>
          <input type="text" v-model="confirmNewPassword" />
        </div>
        <div class="input-wrap">
          <van-button @click="submitBtn()" type="primary" v-if="isForget">提交验证</van-button>
          <van-button @click="passwordBtn()" type="primary" v-else>提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Forget",
  data() {
    return {
      form: {},
      isForget: true,
      zhaohuipsd:true,
      tel:"",
      yanzhengmaList:'',
      zhaohuiTime:0,
      verification:'',
      newPassword:'',
      confirmNewPassword:'',
    };
  },
  created() {
    this.$emit("header", false);
  },
  methods: {
    onClickLeft() {
      history.go(-1)
    },
    // 验证码
    yanzhengmaBtn(){
      if(this.tel == ''){
        this.$toast('请输入手机号');  
        return false
      }
      let data = {
          type:'resetpwd',
          mobile:this.tel
      }
      this.axios.post('https://api.wodelan.com/api/user/sendcaptcha',data).then(res =>{
          console.log(res)
          if(res.data.code == 1){
              this.$toast(res.data.msg);  
              this.yanzhengmaList = res.data.data
              this.zhaohuipsd = false
              this.zhaohuiTime = 60
              var auth_timetimer  = setInterval(() =>{
                  this.zhaohuiTime --;
                  if(this.zhaohuiTime <= 0){
                      this.zhaohuipsd = true;
                      clearInterval(auth_timetimer)
                  }
              },1000)   
          }else{
              this.$toast(res.data.msg);
          }
      })  
    },
    submitBtn(){
      if(this.tel == ''){
        this.$toast('请输入手机号');
        return false
      }
      if(this.verification == ''){
        this.$toast('请输入验证码');
        return false
      }
      if(this.verification != this.yanzhengmaList){
        this.$toast('验证码错误');
        return false  
      }else{
        this.isForget = false
      }
    },
    passwordBtn(){
      if(this.confirmNewPassword == ''){
        this.$toast('请输入确认密码');
        return false
      }
      if(this.newPassword == ''){
        this.$toast('请输入密码')
        return false
      }
      if(this.newPassword != this.confirmNewPassword){
        this.$toast('两次输入的密码不一致')
        return false
      }
      let data = {
        mobile:this.tel,
        newpassword:this.newPassword,
        captcha:this.verification
      }
      this.axios.post('https://api.wodelan.com/api/user/resetpwd',data).then(res =>{
        console.log(res)
        if(res.data.code == 1){
            this.$toast(res.data.msg) 
            setTimeout(() => {
                this.$router.push({path:'./login'})
            }, 1000);
        }else{
            this.$message.error(res.data.msg);
        }
      })
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.forget {
  .wrap {
    padding: 19px 13px;
    .title {
      font-size: 25px;
      font-weight: bold;
    }
    .form-wrap {
      margin-top: 35px;
      .input-wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 34px;
        .label-wrap {
          font-size: 16px;
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;
        }

        input {
          height: 50px;
          font-size: 16px;
          padding: 0 10px;
        }
      }
      .privacy {
        display: flex;
        align-items: center;
        margin: 18px 0;
        span {
          font-size: 12px;
          margin-left: 10px;
        }
      }
      .van-button {
        background: #000000;
        color: #ffffff;
        height: 55px;
        border-color: #000;
        font-size: 18px;
        margin-bottom: 13px;
      }
    }
  }
}
.passwordYanzhengmaBtn{
  font-size: 3.33vw;
  margin-left: 2vw;
}
</style>
