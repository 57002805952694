<template>
  <v-head v-if="header_show" :isBlack="is_black"></v-head>
  <router-view v-on:header="header" v-on:theme="theme" />
</template>


<script>
import head from "@/components/head";

export default {
  name: "App",
  components: {
    "v-head": head,
  },
  data() {
    return {
      header_show: true,
      is_black: true,
    };
  },
  created() {},
  methods: {
    //是否显示头部
    header(bool) {
      this.header_show = bool;
    },
    theme(val) {
      this.is_black = val;
    },
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

.van-nav-bar {
  .van-nav-bar__content {
    height: 62px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .van-nav-bar__left {
      .van-icon {
        color: #000000;
        font-size: 20px;
      }
    }
    .van-nav-bar__title {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.homeTop{
  padding-top: 14.2vw;
}
.spxq_list p img{
  display: block !important;
}
.homeSwiper .van-swipe__indicators{
  bottom: 100px;
}
</style>
