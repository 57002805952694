import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/login.vue";
import Register from "../views/register.vue";
import Forget from "../views/forget.vue";
import RegisterSuccess from "../views/registerSuccess.vue";
import BindingPhone from "../views/bindingPhone.vue"
import PaySuccess from "../views/paySuccess.vue"
import ConfirmOrder from "../views/confirmOrder.vue"
import GoodsDetail from "../views/goodsDetail.vue"
import AboutUs from "../views/aboutUs.vue"
import RepairMaintain from "../views/repairMaintain.vue"
import Product from "../views/product.vue"
import watch from "../views/watch.vue"
import maintain from "../views/maintain.vue"
import myContent from '../views/myContent/myContent.vue';
import orderDetails from '../views/myContent/orderDetails.vue';
import AddressDetails from "../views/myContent/addressDetail.vue"
import AddRepair from "../views/myContent/addRepair.vue"
import aboutUsDetails from '../views/aboutUsDetails.vue'
import scanCodePage from '../views/myContent/scanCodePage.vue'
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,      
    meta:{
      keepAlive:false
    }
  },
  {
    path: "/scanCodePage",
    name: "scanCodePage",
    component: scanCodePage,
    meta:{
      keepAlive:false
    }
  },
  {
    path: "/home",
    name: "homes",
    component: Home,
    meta:{
      keepAlive:false
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta:{
      keepAlive:false
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta:{
      keepAlive:false
    }
  },
  {
    path: "/forget",
    name: "Forget",
    component: Forget,
    meta:{
      keepAlive:false
    }
  },
  {
    path:'/register-success',
    name: "RegisterSuccess",
    component: RegisterSuccess,
    meta:{
      keepAlive:false
    }
  },
  {
    path:'/binding-phone',
    name: "BindingPhone",
    component: BindingPhone,
    meta:{
      keepAlive:false
    }
  },
  {
    path:'/pay-success',
    name: "PaySuccess",
    component: PaySuccess,
    meta:{
      keepAlive:false
    }
  },
  {
    path:'/confirm-order',
    name: "ConfirmOrder",
    component: ConfirmOrder,
    meta:{
      keepAlive:true
    }
  },
  {
    path:'/goods-detail',
    name: "GoodsDetail",
    component: GoodsDetail,
    meta:{
      keepAlive:true
    }
  },
  {
    path:'/about-us',
    name: "AboutUs",
    component: AboutUs,
      meta:{
        keepAlive:true
      }
  },
  {
    path:'/repair-maintain',
    name: "RepairMaintain",
    component: RepairMaintain,
    meta:{
      keepAlive:true
    }
  },
  {
    path:'/product',
    name: "Product",
    component: Product,
    meta:{
      keepAlive:true
    }
  },  
  {
    path:'/myContent',
    name: "myContent",
    component: myContent,
    meta:{
      keepAlive:true
    }
  },
  {
    path:'/orderDetails',
    name: "orderDetails",
    component: orderDetails,
    meta:{
      keepAlive:false
    }
  },
  {
    path:'/address-detail',
    name: "AddressDetails",
    component: AddressDetails,
    meta:{
      keepAlive:false
    }
  },
  {
    path:'/add-repair',
    name: "AddRepair",
    component: AddRepair,
    meta:{
      keepAlive:false
    }
  },{
    path:'/watch',
    name: "watch",
    component: watch,
    meta:{
      keepAlive:true
    }
  },{
    path:'/maintain',
    name: "maintain",
    component: maintain,
    meta:{
      keepAlive:true
    }
  },{
    path:'/aboutUsDetails',
    name: "aboutUsDetails",
    component: aboutUsDetails,
    meta:{
      keepAlive:true
    }
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    //保存当前路由
      localStorage.setItem("preRoute", from.fullPath)
  }
  next()
})

export default router;
