<template>
  <!-- <div class="head-max" >
    <div class="menu" style="background:rgba(255,255,255);box-shadow:0px 3px 6px rgba(0,0,0,0.16)">
      <router-link to="/Home">
        <img   class="logo2" src="../assets/image/logo-black2.png" alt="" />
      </router-link>
      <img
        v-if="show == false"
        class="logo"
        src="../assets/image/menu-black.png"
        alt=""
        @click="openMenu"
      />
    <div class="cross-wrap" v-else>
        <van-icon name="cross" @click="show = false" />
    </div>
    </div>
    <van-popup v-model:show="show" position="top">
      <div class="popup-wrap">
        <div class="cross-wrap">
          <van-icon name="cross" @click="show = false" />
        </div>
        <van-collapse v-model="activeNames" accordion>
          <van-collapse-item title="首页" name="1">
            <div class="children-list">
              <div class="children-item" @click="goHone()">首页</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="关于WODELAN" name="2">
            <div class="children-list">
              <div class="children-item" @click="linktoAbout()">关于WODELAN</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="新品预售" name="3">
            <div
              class="children-list"
              v-for="item in yushouTitle"
              :key="item.od"
            >
              <div class="children-item" @click="linktoYs(item.id)">
                {{ item.name }}
              </div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="维修保养" name="4">
            <div class="children-list">
              <div class="children-item" @click="linktoby(1)">维修中心</div>
              <div class="children-item" @click="linktoby(2)">保养中心</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="产品中心" name="5">
            <div class="children-list">
              <div class="children-item"  @click="linktoby(4)">腕表</div>
              <div class="children-item"  @click="linktoby(3)">工艺品</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="会员中心" name="6">
            <div class="children-list">
              <div class="children-item" @click="linktoby(5)">会员中心</div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </van-popup>
  </div> -->
  <div class="confirm-order">
    <van-nav-bar title="确认订单" left-arrow @click-left="onClickLeft" />
    <div class="wrap">
      <div class="title">配送地址信息</div>
      <div class="user-info" v-if="addressDetails == ''" @click="linktoAddress()">
        <span class="nameinfo">请选择配送地址</span>
        <span class="address"></span>
      </div>
      <div class="user-info" v-else-if="addressDetails == null" @click="linktoAddress()">
        <span class="nameinfo">请选择配送地址</span>
        <span class="address"></span>
      </div>
      <div class="user-info" @click="linktoAddress()" v-else>
        <span class="nameinfo">{{addressDetails.ship_name}}，{{addressDetails.ship_tel}}</span>
        <span class="address">{{addressDetails.province}}{{addressDetails.city}}{{addressDetails.area}}{{addressDetails.ship_addr}}</span>
      </div>
      <!-- 商品详情 -->
      <div class="goods-detail">
        <div class="goods-title">商品详情</div>
        <div class="goods-info">
          <div class="img-box">
            <img :src="detailBannerImg" alt="" />
          </div>
          <div class="info">
            <div class="goods-name">{{name}}</div>
            <div class="namesubtitle">{{subtitle}}</div>
            <span class="goods-count">购买数量：{{num}}</span>
            <span class="goods-size">表带尺寸（厘米）：{{cm}}</span>
            <span class="goods-price">商品单价：￥{{unitPirce}}</span>
            <span class="total-price">总计：{{totalPrice}}</span>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <!-- 订单详情 -->
      <div class="order-detail">
        <div class="order-title">订单详情</div>
        <div class="info-list">
          <div class="info-item">
            <span>付款方式</span>
            <span>{{is_type == 1?'定金+尾款':'全款'}}</span>
          </div>
          <div class="info-item">
            <span>{{is_type == 1?'定金':'全款'}}</span>
            <span>￥{{price}}</span>
          </div>
          <div class="info-item">
            <span>运费</span>
            <span>免费</span>
          </div>
          <div class="info-item">
            <span>总计</span>
            <span style="font-weight: bold; color: #333">￥{{coupon_price}}</span>
          </div>
        </div>
      </div>
      <!-- 备注 -->
      <div class="goods-remark">
        <span>备注</span>
        <textarea rows="7" v-model="content"></textarea>
      </div>
      <!-- 支付方式 -->
      <div class="pay-method">
        <div class="pay-title">支付方式</div>
        <div class="agreement">
          <van-checkbox v-model="checked1"></van-checkbox>
          <div class="pay-img-box">
            <img src="../assets/image/weixin-pay.png" alt="" />
          </div>
          <span class="pay-text">微信支付</span>
        </div>
      </div>
      <!-- 发票信息 -->
      <div class="invoice-info">
        <div class="invoice-title">发票信息</div>
        <div class="invoice-text">
          使用电子发票。您可以参阅使用说明，在包裹内获得您的电子发票提取码，然后获取您的电子发票。
        </div>
      </div>
      <!-- 优惠券选择 -->
      <div class="invoice-info">
        <div class="invoice-title">优惠券选择</div>
        <div class="choice_coupon" @click="couponCho">
          已选择: {{coupon_name}}
          <img src="../assets/image/right.png" alt="">
        </div>
      </div>
      <!-- 《账户注册协议》, 《发货和退货协议》 -->
      <div class="check-agree">
        <van-checkbox v-model="checked2"></van-checkbox>
        <span class="agree-text"
          >我已阅读并同意 《账户注册协议》, 《发货和退货协议》</span
        >
      </div>
      <div class="pay-btn">
        <div class="deposit">{{is_type == 1?'定金':'全款'}}共计：{{coupon_price}}</div>
        <van-button type="primary" @click="payBtn()">立即支付</van-button>
      </div>
    </div>
    <!-- 优惠券弹窗 -->
        <div class="popMaxCoupon" v-if="isShowPop">
        <van-nav-bar title="优惠券折扣" left-arrow @click-left="closePop" />
        <div class="couponPop">
            <div class="couponList">
                <div class="coupon"
                    v-for="item,i in couponCardList" 
                    :key="i"
                    @click="coupon(item,item.user_coupon_id)"
                >
                    <div class="couponCho"><span>√</span></div>
                    <div class="couponTitle">
                        <img src="../assets/image/quan4.png" alt="">
                        <div>{{item.name}}</div>
                    </div>
                    <div class="couponContent">
                        <div class="couponPrice"><span>￥</span>{{item.amount}}</div>
                        <div>使用范围: {{item.type}}</div>
                        <div>{{item.start_time}} 至 {{item.end_time}} 有效</div>
                    </div>
                </div>
                <div class="coupon nocoupon" @click="nocoupon()">
                    <div class="couponCho"><span>√</span></div>
                    不使用优惠券
                </div>
            </div>
        </div>
      </div>
  </div>

</template>

<script>
export default {

  name: "ConfirmOrder",
  data() {
    return {
      user_coupon_id:'',  //选中的优惠券id
      isShowPop:false,  //控制优惠券弹窗
      couponActive:1,  //选中的优惠券
      weixiu_price:'',  //当前选择的维修订单价格
      coupon_price:'',  //使用优惠券后的价格
      coupon_name:'不使用优惠券',  //选中优惠券名称
      m_id:'',  //保存订单id
      couponCardList:[],
      activeNames:'',
      show:false,
      remark: "",
      checked1: true,
      checked2: true,
      num:'',
      amount:'',
      goods_id:'',
      strap_size:'',
      price:'',
      unitPirce:'',
      totalPrice:'',
      cm:'',
      name:'',
      subtitle:'',
      radio:'',
      cmValue:'',
      content:'',
      addressDetails:'',
      method:'',
      openid:'',
      pay:'',
      baseUrl:'',
      detailBannerImg:'',
      is_type:'',
    };
  },
  created() {
    this.$emit("header", false);
    console.log(this.$route.query)
    this.is_type = localStorage.getItem('is_type') //是否预售
    this.num = localStorage.getItem('num') //数量
    this.amount = localStorage.getItem('amount') //总价
    this.goods_id = localStorage.getItem('goods_id')  //商品id
    this.strap_size = localStorage.getItem('strap_size') //尺寸
    this.price = localStorage.getItem('price') //定金单价
    this.unitPirce = localStorage.getItem('unitPirce') //商品单价
    this.totalPrice = this.num * this.unitPirce //商品总价
    this.cm = localStorage.getItem('cm')
    this.name = localStorage.getItem('shangpinname')
    this.subtitle = localStorage.getItem('subtitle')
    this.detailBannerImg = localStorage.getItem('detailBannerImg')
    this.indexGoods();
    if(localStorage.getItem('addDetails')){
      var dizhi = localStorage.getItem('addDetails')
      this.addressDetails = JSON.parse(dizhi)
      this.cmValue = this.addressDetails.id
      console.log(this.addressDetails)
    }

    this.getDefaultAddress()
    this.getCoupponList_pay()

    // this.coupon_price = localStorage.getItem('amount')
    let num = localStorage.getItem('amount')
    num = Number(num)
    num = num.toFixed(2)
    this.coupon_price = num
  },
  methods: {
    coupon(item,idx){
        this.couponActive = item
        this.coupon_name = item.name
        this.user_coupon_id = idx  //优惠券id
        if(this.amount-item.amount>=0){
            // this.coupon_price = this.amount-item.amount
            let num = this.amount-item.amount
            num = Number(num)
            num = num.toFixed(2)
            this.coupon_price = num
        }else{
            this.coupon_price = 0.00
        }
        this.isShowPop = false
    },
    //不使用优惠券
    nocoupon(){
        this.couponActive = ''
        // this.coupon_price = this.amount
        let num = this.amount
        num = Number(num)
        num = num.toFixed(2)
        this.coupon_price = num
        
        this.user_coupon_id = ''  //优惠券id
        this.isShowPop = false
        this.coupon_name='不使用优惠券'
    },
    //下单时获取优惠券列表
    getCoupponList_pay(){
        let data = {
            user_id : localStorage.getItem('user_id'),
            type : 1,   //1购买商品 2维修 3保养
            amount : this.amount
        }
        this.axios.post('https://api.wodelan.com/api/index/getUserCoupon',data).then(res =>{
            let data = JSON.parse(res.data.data).data  //优惠券列表
            console.log('下单时优惠券列表',data);
            this.couponCardList = data
        }) 
    },
    //关闭弹窗
    closePop(){
      this.isShowPop = false
    },
    //打开弹窗
    couponCho(){
      this.isShowPop = true
    },
     openMenu() {
      this.show = true;
    },
    closeHead(){
      this.show = false
    },
    indexGoods() {
      this.axios
        .post("https://api.wodelan.com/api/index/indexGoods")
        .then((res) => {
          this.yushouTitle = JSON.parse(res.data.data);
          console.log(this.yushouTitle);
        });
    },
    linktoYs(id) {
      this.$router.push({ path: "../views/goods-detail", query: { id: id } });
      this.show = false;
    },
    linktoAbout(){
      this.$router.push({path:'../views/about-us'})
      this.show = false;
    },
    goHone(){
      this.$router.push({path:'../views/home'})
      this.show = false
    },
    linktoby(id){
      if(id == 1){
        this.$router.push({path:'../views/repair-maintain'})
        this.show = false
      }
      if(id == 2){
        this.$router.push({path:'../views/maintain'})
        this.show = false
      }
      if(id == 3){
        this.$router.push({path:'../views/product'})
        this.show = false
      }
      if(id == 4){
        this.$router.push({path:'../views/watch'})
        this.show = false
      }
      if(id == 5){
        this.$router.push({path:'../views/myContent/myContent'})
        this.show = false
      }
    },
    // 获取默认地址
    getDefaultAddress(){
      let data = {
        user_id : localStorage.getItem('user_id')
      }
      this.axios.post('https://api.wodelan.com/api/index/getDefaultAddress',data).then(res =>{
        console.log(res)
        console.log(JSON.parse(res.data.data))
        this.addressDetails = JSON.parse(res.data.data)
        this.cmValue = this.addressDetails.id
      })
    },
    onClickLeft() {
      // history.go(-1)
      this.$router.push({ path: '/goods-detail', query: { id: this.goods_id } })
    },
    payBtn(){
      if(this.addressDetails == ''){
          this.$toast('请选择地址');
          return false
      }
      if(this.checked1 == ''){
         this.$toast('请选择支付方式');
         return false
      }
      if(this.checked1 == ''){
         this.$toast('请勾选我已同意');
         return false
      }
      if(localStorage.getItem('openId')){
        console.log('有')
        this.method = 'mp'
        this.openId = localStorage.getItem('openId')
      }else{
        this.method = 'wap'
        this.openId = ''
      }
      let data = {
          method:this.method,
          openid:this.openId,
          goods_id : this.goods_id,
          user_id : localStorage.getItem('user_id'),
          type:1,
          amount:this.coupon_price,
          strap_size:this.strap_size,
          num:this.num,
          address_id:this.cmValue,
          content:this.content,
          user_coupon_id:this.user_coupon_id
      }
      console.log(data,'支付信息');
      this.axios.post('https://api.wodelan.com/api/index/order_pay',data).then(res =>{
          console.log(res)
          console.log(JSON.parse(res.data.data))
          var we = JSON.parse(res.data.data)
          this.pay = JSON.parse(res.data.data)
          this.payImg = we.img
          this.order_id = we.order_id
          if(res.data.code == 1){
            // 操作成功调用微信支付
            // this.onBridgeReady()
              // this.$router.push({path:'./pay',query:{url:this.payImg,total : this.amount,order_id:this.order_id,type:1}})

              if(data.amount == 0){
                  this.$router.push('/pay-success')
              }else{
                 this.onBridgeReady()
              }
          }else{
              this.$toast(res.data.msg);
          }
      })
      this.$router.push({path:'./pay',query:{id:0}})
    },
    onBridgeReady(){
      console.log(this.pay)

      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId":this.pay.pay.appId,     //公众号ID，由商户传入     
            "timeStamp":this.pay.pay.timeStamp,         //时间戳，自1970年以来的秒数     
            "nonceStr":this.pay.pay.nonceStr, //随机串     
            "package":this.pay.pay.package,     
            "signType":this.pay.pay.signType,         //微信签名方式：     
            "paySign":this.pay.pay.paySign //微信签名 
          },
          function(res){
            console.log(res)
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
          // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              console.log('成功')  
              window.location.href = 'https://wdlh5.mb.shizuyx.com/pay-success?imgUrl=' +this.baseUrl
              // this.$router.push({path:'./pay-success'})
          } else{
            console.log('失败')
          }
      }); 
    },
    // 跳转到地址列表
    linktoAddress(){
      this.$router.push({path:'./myContent/myContent',query:{addressT:'1'}})
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
// .confirm-order {
  .choice_coupon{
    border: 1px solid #bbb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 15px;
    img{
      width: 10px;
      height: 10px;
    }
  }
  .wrap {
    padding-bottom: 60px;
    .title {
      padding: 21px 17px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #333333;
    }
    .user-info {
      margin: 0 17px;
      display: flex;
      flex-direction: column;
      padding: 8px 15px;
      background: rgba(255, 255, 255, 0.39);
      border: 1px solid #f0f0f0;
      font-size: 13px;
      color: #333333;
      .address {
        margin-top: 10px;
      }
    }
    .goods-detail {
      padding: 0 17px;
      margin-top: 15px;
      .goods-title {
        font-size: 16px;
        color: #333;
        font-weight: bold;
      }
      .goods-info {
        display: flex;
        margin-top: 10px;
        .img-box {
          width: 87px;
          height: 87px;
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          .goods-name {
            font-size: 14px;
            color: #000000;
            margin-bottom: 5px;
          }
          .namesubtitle {
            font-size: 12px;
            color: #5c5c5c;
            margin-bottom: 5px;
          }
          span {
            font-size: 12px;
            color: #5c5c5c;
            margin-bottom: 5px;
          }
          .total-price {
            font-weight: bold;
            color: #333;
          }
        }
      }
    }
    .line {
      margin-top: 10px;
      width: 100%;
      height: 15px;
      background: #f0f0f0;
    }
    .order-detail {
      padding: 12px 17px;
      .order-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 14px;
      }
      .info-list {
        .info-item {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #5c5c5c;
          margin-bottom: 12px;
        }
      }
    }
    .goods-remark {
      padding: 0px 17px 21px;
      display: flex;
      flex-direction: column;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
      }
      textarea {
        width: 100%;
        background: #f0f0f0;
        border: none;
        outline: none;
        font-size: 14px;
        padding: 4px 10px;
        box-sizing: border-box;
        resize: none;
      }
    }
    .pay-method {
      padding: 0 17px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .pay-title {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 15px;
      }
      .agreement {
        display: flex;
        align-items: center;
        .van-checkbox {
          margin-right: 6px;
          .van-checkbox__icon {
            .van-badge__wrapper {
              width: 20px;
              height: 20px;
            }
          }
        }
        .pay-img-box {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }
        }
        .pay-text {
          font-size: 14px;
          color: #333333;
          margin-left: 3px;
        }
      }
    }
    .invoice-info {
      padding: 0 17px 30px;

      .invoice-title {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 14px;
      }
      .invoice-text {
        font-size: 12px;
        line-height: 24px;
      }
    }
    .check-agree {
      padding: 0 17px 10px;
      display: flex;
      align-items: center;
      .agree-text {
        font-size: 12px;
        color: #333;
        margin-left: 8px;
      }
    }
    .pay-btn {
      width: 100%;
      position: fixed;
      bottom: 0;
      background: #fff;
      display: flex;
      .deposit {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        background: #f0f0f0;
      }
      .van-button {
        width: 165px;
        height: 55px;
        background: #000000;
        border-color: #000;
        color: #fff;
      }
    }
  }
// }
</style>
