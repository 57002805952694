<template>
  <div class="pay-success">
    <van-nav-bar title="支付成功" left-arrow @click-left="onClickLeft" />
    <div class="wrap">
      <div class="icon-wrap">
        <img src="../assets/image/register-success.png" alt="" />
        <span class="desc">支付成功</span>
      </div>
      <div class="remark">
        <!-- 订单号：<span>GH456K4THYK885</span> -->
        支付成功，查看订单请点击下方按钮进行跳转
      </div>

      <div class="btn-wrap">
        <van-button type="primary" @click="ckdd()">查看订单</van-button>
        <span>订单有任何问题，可联系客服进行处理</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaySuccess",
  data() {
    return {};
  },
  created() {
    this.$emit("header", false);
  },
  methods: {
    onClickLeft() {
      this.$router.push('/myContent')
    },
    ckdd(){
      this.$router.push({path:'./myContent/myContent'})
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.pay-success {
  .wrap {
    padding: 43px 20px;
    .icon-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .desc {
        font-size: 18px;
        color: #000000;
        font-weight: bold;
        margin-top: 10px;
      }
    }
    .remark {
      width: 329px;
      margin: 12px auto 59px;
      text-align: center;
      line-height: 22px;
      font-size: 14px;
      span {
        text-decoration: underline;
      }
    }
    .btn-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      .van-button {
        width: 151px;
        height: 37px;
        background: #000000;
        font-size: 16px;
        color: #ffffff;
        border-color: #000000;
        margin-bottom: 9px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}
</style>
