// import Vue from 'vue'
import { createApp } from "vue";
import App from "./App.vue";
import 'lib-flexible'
import router from "./router";
import store from "./store";
import Vant from 'vant';
import 'vant/lib/index.css';
// import BaiduMap from 'vue-baidu-map'
import axios from 'axios'
// Vue.prototype.axios = axios;
// Vue.use(axios)
// Vue.use(store);
// Vue.use(Vant);
// Vue.use(BaiduMap, {
//     ak: 'IPYuq09qXdQzYuGxWyg8Q0n9ews1zt9d' //官方提供的ak秘钥
//   })


import VueAxios from 'vue-axios'
createApp(App).use(store).use(router).use(Vant).use(VueAxios,axios).mount("#app");



// new Vue({
//     el: '#app',
//     router,
//     components: { App },
//     template: '<App/>'
//   })
