<template>
  <div class="goods-detail homeTop">
    <v-head v-if="header_show" :isBlack="is_black"></v-head>
    <div class="goods-wrap">
      <div class="swipe-wrap" :style="`height:${clientWidth}px`">
        <van-swipe> 
          <van-swipe-item v-for="(item, index) in swiperList" :key="index">
            <img
              :src="item"
              :style="`height:${clientWidth}px;width:100%`"
              alt=""
            />
          </van-swipe-item>

          <template #indicator="{ active, total }">
            <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
          </template>
        </van-swipe>
      </div>
      <div class="price">
        <div class="amount">￥{{shangpinList.price}}</div>
        <span class="goods-name">{{shangpinList.name}}</span>
        <div class="price_name">{{shangpinList.subtitle}}</div>
        <!-- 发货时间 -->
        <div class="fhTime"  v-if="shangpinList.is_pay == 1">{{shangpinList.sdate}} - {{shangpinList.edate}}开始发货</div>
      </div>
      <!-- 购买数量表带尺寸 -->
      <div class="payNum"  v-if="shangpinList.is_pay == 1">
        <div class="payRight">
          <div class="payNumTitle">购买数量</div>
          <!-- <div class="numRow">
            <div class="edit" @click="edit()">-</div>
            <div class="numDetails"><input type="text" v-model="payNum"></div>
            <div class="add" @click="add()">+</div>
          </div> -->
          <van-stepper input-width="80px" button-size="32px" v-model="payNum" />
        </div>
        <!-- <div class="payRight payLeft">
          <div class="payNumTitle">表带尺寸(厘米)</div>
          <div class="cmSelect">
            <select name="" id="" v-model="selectValue" @change="selectClick">
              <option selected="selected" value="">请选择尺寸</option>
              <option v-for="(item,index) in chicunList" 
              :key="index" 
              :value="`${item.name},${item.id}`"
              >{{item.name}}</option>
            </select>
          </div>
        </div> -->
      </div>
      <div class="line"></div>
      <!-- 表壳、表盘和指针、机芯和功能、表链 -->
      <div class="tab-wrap" v-if="shangpinList.type == 1 || shangpinList.type == 2 || shangpinList.type == 3">
        <van-tabs v-model="active">
          <van-tab title="表壳" name="a">
            <div class="tab-content">
              <div class="list">
                <div class="item">
                  <span class="desc">防水性能: </span>
                  <span class="count">{{shangpinList.waterproof}}</span>
                </div>
                <div class="item">
                  <span class="desc">尺寸: </span>
                  <span class="count">{{shangpinList.size}}</span>
                </div>
                <div class="item">
                  <span class="desc">表镜: </span>
                  <span class="count"
                    >{{shangpinList.watch_glass}}</span
                  >
                </div>
                <div class="item">
                  <span class="desc">材质: </span>
                  <span class="count">{{shangpinList.material}}</span>
                </div>
                <div class="item">
                  <span class="desc">形状: </span>
                  <span class="count">{{shangpinList.shape}}</span>
                </div>
                <div class="item">
                  <span class="desc">表壳厚度: </span>
                  <span class="count">{{shangpinList.thickness}} </span>
                </div>
                <div class="item">
                  <span class="desc">表耳阔度: </span>
                  <span class="count">{{shangpinList.width}} </span>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="表盘和指针" name="b">
            <div class="tab-content">
              <div class="list">
                <div class="item">
                  <span class="desc">指针: </span>
                  <span class="count">{{shangpinList.pointer}}</span>
                </div>
                <div class="item">
                  <span class="desc">颜色: </span>
                  <span class="count">{{shangpinList.color}}</span>
                </div>
              </div>
            </div></van-tab
          >
          <van-tab title="机芯和功能" name="c">
            <div class="tab-content">
              <div class="list">
                <div class="item">
                  <span class="desc">机芯: </span>
                  <span class="count">{{shangpinList.pointer}}</span>
                </div>
                <div class="item">
                  <span class="desc">储存功能: </span>
                  <span class="count">{{shangpinList.save_function}}</span>
                </div>
                <div class="item">
                  <span class="desc">机芯类型: </span>
                  <span class="count"
                    >{{shangpinList.movement_type}}</span
                  >
                </div>
                <div class="item">
                  <span class="desc">机芯型号: </span>
                  <span class="count">{{shangpinList.movement_model}}</span>
                </div>
              </div>
            </div></van-tab
          >
          <van-tab title="表链" name="d">
            <div class="tab-content">
              <div class="list">
                <div class="item">
                  <span class="desc">材质: </span>
                  <span class="count">{{shangpinList.bracelet_material}}</span>
                </div>
                <div class="item">
                  <span class="desc">表扣: </span>
                  <span class="count">{{shangpinList.bracelet_clasp}}</span>
                </div>
              </div>
            </div></van-tab
          >
        </van-tabs>
      </div>
      <div v-else>
        <div class="item2">
          <span style="color:#888;">产品介绍: </span>
          <div v-html="shangpinList.content"></div>
        </div>
      </div>
      <!-- 分享 -->
      <div class="share">
        <span class="share-text">分享给朋友：点击屏幕右上角分享</span>
        <!-- <img
          style="margin-right: 12px"
          src="../assets/image/share1.png"
          alt=""
          @click="getShareInfo()"
        /> -->
        <!-- <img src="../assets/image/share2.png" alt="" /> -->
      </div>
      <!-- <div class="goods-img">
        <img src="../assets/image/goods2.png" alt="" />
      </div> -->
      <!-- 热门产品 -->
      <div class="heat-goods">
        <div class="title">热门产品</div>
        <div class="loopRow">
          <van-swipe :loop="false" :width="180" :show-indicators="false" class="loopswiper">
            <van-swipe-item @click="linktoDq(item.id)" v-for="(item,index) in hrefList" :key="index">
              <img :src="item.thumb" alt="">
              <div class="looptext">{{item.name}}</div>
            </van-swipe-item>

          </van-swipe>
        </div>

      </div>
      <div class="line"></div>
      <!-- 商品详情 -->
      <div class="goods-rich-text">
        <div class="title">商品详情</div>
          <div class="spxq">
              <div class="spxq_list" v-html="shangpinList.content"></div>
          </div>
      </div>
      <div class="payFooter" v-if="shangpinList.is_pay == 1">
        <div class="payFooter_right">{{is_type == 1?'定金':'全款'}}共计：￥{{shangpinList.deposit * payNum}}</div>
        <div class="payBtn" @click="yudingBtn()">立即{{is_type == 1?'预定':'购买'}}</div>
      </div>
      <!-- <div class="goods-btn" v-else>
        <van-button type="primary" class="share-friend">分享给朋友</van-button>
        <van-button type="primary" class="customer">联系客服</van-button>
      </div> -->

    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import $ from 'jquery';
import head from "@/components/head";
export default {
  name: "GoodsDetail",
  components: {
    "v-head": head,
  },
  data() {
    return {
      header_show: true,
      is_black: true,
      form: {},
      clientWidth: "",
      active: "b",
      swipeList2: [
        { img: require("../assets/image/goods1.png") },
        { img: require("../assets/image/goods1.png") },
        { img: require("../assets/image/goods1.png") },
        { img: require("../assets/image/goods1.png") },
      ],
      goods_id:'',
      goodsDetails:'',
      swiperList:'',
      shangpinList:'',
      hrefList:'',
      payNum:1,
      chicunList:[],
      selectValue:'20cm',
      selectLimi:'',
      cmValue:'',
      imgUrl:'',
      desc:'',
      titlename:'',
      is_type:'',
    };
  },
  created() {
    this.clientWidth = document.documentElement.clientWidth - 28;
    this.goods_id = this.$route.query.id
    this.detailsList()
    this.selectList()
    this.isBlack = true
    this.getShareInfo()
  },
  methods: {
    header(bool) {
      this.header_show = bool;
    },
    theme(val) {
      this.is_black = val;
    },
    detailsList(){
      let data = {
        goods_id :this.goods_id
      }
      this.axios.post('https://api.wodelan.com/api/index/goodsDetail',data).then(res =>{
          console.log(JSON.parse(res.data.data))
          this.goodsDetails = JSON.parse(res.data.data)
          var wqw = JSON.parse(res.data.data)
          this.is_type = wqw.goodsDetail.is_type
          this.swiperList = wqw.goodsDetail.thumb
          this.shangpinList = wqw.goodsDetail
          console.log(this.shangpinList)
          this.hrefList = wqw.popular
          console.log(this.goodsDetails.goodsDetail)
          this.imgUrl = this.goodsDetails.goodsDetail.imgUrl
          this.desc = this.goodsDetails.goodsDetail.desc
          console.log( this.desc)
          this.titlename = this.goodsDetails.goodsDetail.name
          console.log(this.goodsDetails.goodsDetail.thumb[0])
          localStorage.setItem('detailBannerImg',this.goodsDetails.goodsDetail.thumb[0])
      })
    },
    linktoDq(id){
      this.$router.push({path:'./goods-detail',query:{id:id}})
    },
    edit(){
      if(this.payNum <= 1){
        this.$toast('购买数量不能小于1');
        return false 
      }else{
        this.payNum --
      }
    },
    add(){
      this.payNum ++
    },
    selectList(){
      this.axios.post('https://api.wodelan.com/api/index/strapSize').then(res =>{
          this.chicunList = JSON.parse(res.data.data)
          console.log(this.chicunList)
      })
    },
    selectClick(){
      let [id,name] = this.selectValue.split(",");
      console.log(id)
      console.log(name)
      this.selectLimi = name //value值
      this.cmValue = id //显示的值
      
    },
        // 点击立即预定
    yudingBtn(){
      if(!localStorage.getItem('user_id')){
          console.log('没有登录')
          this.$router.push({path:'./login'})
          return false
      }else{
          console.log('登录了')
      }

      console.log(this.cmValue)
      if(this.selectValue == ''){
          this.$toast('请选择表带尺寸');
          return false
      }
      var total = this.shangpinList.deposit * this.payNum
      localStorage.setItem('is_type',this.is_type)
      localStorage.setItem('goods_id',this.goods_id)
      localStorage.setItem('strap_size',this.selectLimi)
      localStorage.setItem('num',this.payNum)
      localStorage.setItem('amount',total)
      localStorage.setItem('price',this.shangpinList.deposit)
      localStorage.setItem('unitPirce',this.shangpinList.price)
      localStorage.setItem('cm',this.cmValue)
      localStorage.setItem('shangpinname',this.shangpinList.name)
      localStorage.setItem('subtitle',this.shangpinList.subtitle)
      this.$router.push({path:'./confirm-order'})
    },
    getShareInfo(){//如果分享的内容会根据情况变化，那么这里可以传入分享标题及url
      var data={//请求参数
        // url : this.jmUrl,
        // token : this.token,
        // code : this.code
        url:'/goods-detail?id=' + this.goods_id
      }
      this.axios.post('https://api.wodelan.com/api/index/getJsApi_ticket',data)//这里我写了一个公用的接口请求js，这里正常axios请求就可以，只要拿到数据都可以
        .then(res => {
          console.log(res)
          localStorage.setItem("jsapi_ticket", res.data.data.jsapi_ticket);
          //拿到后端给的这些数据
          let appId = res.data.data.appid;
          let timestamp = res.data.data.timestamp;
          let nonceStr = res.data.data.noncestr; 
          let signature = res.data.data.signature;
          let title = this.titlename
          let imgUrl = this.imgUrl
          let desc = this.desc
          let id = this.goods_id
          wx.config({
            debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId,         // 必填，公众号的唯一标识，填自己的！
            timestamp: timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
            nonceStr: nonceStr,   // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1
            jsApiList: [
              'updateAppMessageShareData',
              'updateTimelineShareData',
              'onMenuShareAppMessage',
              'onMenuShareTimeline'
            ]
          })
          wx.ready(function () {
            console.log('朋友圈')
              //分享给朋友
              if(wx.updateAppMessageShareData){
                wx.updateAppMessageShareData({
                  title: title,   // 分享时的标题
                  link: 'https://h5.wodelan.com/goods-detail?id=' + id,     // 分享时的链接
                  imgUrl: imgUrl,    // 分享时的图标
                  desc: desc, 
                  success: function () {
                    console.log("分享成功");
                  },
                  cancel: function () {
                    console.log("取消分享");
                  }
                });
              }else{
                wx.onMenuShareAppMessage({
                  title: title,   // 分享时的标题
                  link: 'https://h5.wodelan.com/goods-detail?id=' + id,     // 分享时的链接
                  imgUrl: imgUrl,    // 分享时的图标
                  desc: desc, 
                  success: function () {
                    console.log("分享成功");
                  },
                  cancel: function () {
                    console.log("取消分享");
                  }
                });
              }

              //分享到朋友圈
              if(wx.updateTimelineShareData){
                wx.updateTimelineShareData({
                  title: title,
                  link: 'https://h5.wodelan.com/goods-detail?id=' + id,
                  imgUrl: imgUrl,
                  type: '',
                  desc: desc,
                  dataUrl: '', 
                  success: function () {
                    console.log("分享成功");
                  },
                  cancel: function () {
                    console.log("取消分享");
                  }
                });
              }else{
                wx.onMenuShareTimeline({
                  title: title,
                  link: 'https://h5.wodelan.com/goods-detail?id=' + id,
                  imgUrl: imgUrl,
                  type: '',
                  desc: desc,
                  dataUrl: '', 
                  success: function () {
                    console.log("分享成功");
                  },
                  cancel: function () {
                    console.log("取消分享");
                  }
                });
              }

          })
      })
}
  },
  updated(){
    $('.spxq_list').find('img').css('display','block')
  },
  mounted() {},
};
</script>
<style lang="scss" >
.item2{
  padding: 12px 26px;
  font-size: 14px;
}
.goods-detail {
  padding-bottom: 60px;
  .goods-wrap {
    .swipe-wrap {
      margin: 10px 14px;
      margin-bottom: 5px;
      .custom-indicator {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 70px;
        height: 30px;
        background: rgba(0, 0, 0, 0.2);
        opacity: 1;
        border-radius: 100px;
        color: #fff;
        text-align: center;
        font-size: 5.4vw;
        line-height: 30px;

      }
    }
    .price {
      padding: 0 14px 8px;
      display: flex;
      flex-direction: column;
      .amount {
        font-size: 25px;
        font-weight: bold;
        color: #000;
      }
      .goods-name {
        font-size: 16px;
      }
      .price_name {
        font-size: 12px;
        color: #5c5c5c;
        margin-top: 2px;
      }
    }
    .line {
      height: 9px;
      background: rgba(246, 246, 246, 0.39);
    }
    .tab-wrap {
      .van-tabs {
        .van-tabs__wrap {
          .van-tabs__nav {
            .van-tabs__line {
              background: #000000;
            }
            .van-tab--active {
              .van-tab__text {
                font-weight: bold;
              }
            }
          }
        }
        .van-tabs__content {
          .van-tab__panel {
            .tab-content {
              font-size: 14px;
              padding: 11px 26px 0;
              .list {
                .item {
                  display: flex;
                  margin-bottom: 14px;
                  .desc {
                    color: #888;
                  }
                  .count {
                    color: #333;
                    margin-left: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .share {
      height: 42px;
      border-top: 1px solid rgba(246, 246, 246, 0.39);
      padding: 0 26px;
      display: flex;
      align-items: center;
      .share-text {
        font-size: 14px;
        color: #333;
        margin-right: 12px;
      }
    }
    .goods-img {
      width: 100%;
      height: 115px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .heat-goods,
    .goods-rich-text {
      .title {
        font-size: 18px;
        font-weight: bold;
        margin: 14px;
      }
    }
    .goods-btn {
      display: flex;
      height: 60px;
      background: rgba(255, 255, 255, 0.39);
      box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
      .van-button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 16px;
      }
      .share-friend {
        background: #ffffff;
        border-color: #ffffff;
        color: #000;
      }
      .customer {
        background: #000000;
        border-color: #000000;
        color: #ffffff;
      }
    }
  }
}
.loopRow{
  width: 93.4667vw;
  margin: 0 auto;
}
.loopswiper{
  img{
    width: 43.7333vw;
    height: 43.7333vw;
  }
  .looptext{
    font-size: 2.8vw;
    color: #000000;
    text-align: center;
  }
}
.spxq_list{
  width: 93.4667vw;
  margin: 0 auto;
  font-size: 3.333vw;
  color: #000000;
  line-height: 3.333vw;
  padding-bottom: 4vw;
  >>>img{
    display: block !important;
  }
  p{
    >>>img{
      display: block !important;
    }
  }
  >>>p{
    img{
      display: block !important;
    }
  }
}

.spxq_list p img{
  display: block !important;
}
.spxq_list >>> p  img{
  display: block !important;
  /deep/ img{
    display: block !important;
  }
}
.spxq_list p img{
  display: block !important;
}
.spxq_list >>> img { display: block !important; }
.fhTime{
  width: 56vw;
  height: 7.4667vw;
  border: 0.2667vw solid #D3B67D;
  font-size: 2.8vw;
  text-align: center;
  line-height: 7.4667vw;
  color: #D3B67D;
  margin-top: 1.2vw;
}
.payNum{
  width: 100%;
  height: 28vw;
  border-top: 2.1333vw solid #F6F6F6;
  display: flex;
  .payRight{
    margin-left: 1.8667vw;

    .payNumTitle{
      font-size: 3.3333vw;
      color: #5C5C5C;
      padding-top: 4.6667vw;
    }
    .numRow{
      width: 48.8vw;
      height: 11.8667vw;
      border: 0.2667vw solid #000000;
      margin-top: 2.4vw;
      display: flex;

      .edit{
        width: 13.6vw;
        line-height: 11.8667vw;
        text-align: center;
      }
      .add{
        width: 13.6vw;
        line-height: 11.8667vw;
        text-align: center;   
      }
      .numDetails{
        width: 21.7333vw;
        text-align: center;
        height: 100%;
        input{
          border: none;
          width: 100%;
          height: 100%;
          background: #F6F6F6;
          text-align: center;
          font-size: 4.2667vw;
          color: #000000;
        }
      }
    }
  }
  .payLeft{
    margin-left: 4.2667vw !important;
    width: 38.9333vw !important;
    .cmSelect{
      width: 38.9333vw;
      height: 7.8667vw;
      margin-top: 2.4vw;
      select{
        width: 100%;
        height: 100%;
        border: 0.2667vw solid #000000;
        outline: none;
      }
    }
  }
}
.payFooter{
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  height: 14vw;
  box-shadow: 0px -0.6667vw 1.4667vw rgba(0, 0, 0, 0.16);
  display: flex;
  z-index: 99;
  .payFooter_right{
    width: 50.4vw;
    text-align: center;
    line-height: 14vw;
    font-size: 3.7333vw;
    font-weight: bold;
  }
  .payBtn{
    width: 49.6vw;
    background: #000000;
    font-size: 4.2667vw;
    text-align: center;
    font-weight: bold;
    line-height: 14vw;
    color: #ffffff;
  }
}
.van-stepper{
  margin-top: 3vw;
}
</style>
