<template>
  <div class="home homeTop">
     <div >
    <!-- 菜单 -->
    <div
      class="menu"
      v-if="show == false"
      :style="`background:${
        isBlack ? 'rgba(255, 255, 255, 0.39)' : '#000000'
      };box-shadow:${isBlack ? ' 0px 3px 6px rgba(0, 0, 0, 0.16)' : ''}`"
     >
     
    <!-- <div class="menu" style="background:rgba(255,255,255,0.39);box-shadow:0px 3px 6px rgba(0,0,0,0.16)"> -->
      <img
        class="logo2"
        src="../assets/image/logo-black2.png"
        alt=""
        v-if="isBlack"
      />
      <img v-else class="logo2" src="../assets/image/logo2.png" alt="" />
      <img
        v-if="isBlack"
        class="logo"
        src="../assets/image/menu-black.png"
        alt=""
        @click="openMenu"
      />
      <img
        v-else
        class="logo"
        src="../assets/image/menu.png"
        alt=""
        @click="openMenu"
      />
    </div>
    <van-popup v-model:show="show" position="top">
      <div class="popup-wrap">
        <div class="cross-wrap">
          <van-icon name="cross" @click="show = false" />
        </div>
        <van-collapse v-model="activeNames" accordion>
          <van-collapse-item title="首页" name="1">
            <div class="children-list">
              <div class="children-item" @click="goHone()">首页</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="关于WODELAN" name="2">
            <div class="children-list">
              <div class="children-item" @click="linktoAbout()">关于WODELAN</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="新品预售" name="3">
            <div
              class="children-list"
              v-for="item in yushouTitle"
              :key="item.od"
            >
              <div class="children-item" @click="linktoYs(item.id)">
                {{ item.name }}
              </div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="维修保养" name="4">
            <div class="children-list">
              <div class="children-item" @click="linktoby(1)">维修中心</div>
              <div class="children-item" @click="linktoby(2)">保养中心</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="产品中心" name="5">
            <div class="children-list">
              <div class="children-item"  @click="linktoby(4)">腕表</div>
              <div class="children-item"  @click="linktoby(3)">工艺品</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="会员中心" name="6">
            <div class="children-list">
              <div class="children-item" @click="linktoby(5)">会员中心</div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </van-popup>
  </div> 
    <!-- 沃德兰·品不凡 -->
    <!-- <div class="artisan" :style="`background: #000 url(${bannerUsL}) no-repeat;background-size: 100%;background-position: top center;`">
      <img src="../assets/image/text-bg.png" alt="" />
    </div> -->
    <div class="homeSwiper">
      <van-swipe class="my-swipe " :autoplay="3000" indicator-color="white" >
        <van-swipe-item v-for="(item,index) in bannerUsL" :key='index'>
          <img :src="item" alt="" >
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="updowm">
      <img src="../assets/image/arrow-down.png" alt="" />
    </div>
    <!-- 关于我们 -->
    <div class="about">
      <div class="title">
        <span class="chinese">关于我们</span>
        <span class="english">ABOUT OUS</span>
      </div>
      <div class="describe">
        {{aboutUsList.name}}
      </div>
      <div class="img-list">
        <div class="img-item"
          v-for="(item, index) in aboutImgList" 
          :key="index"
          :style="`background: #000 url(${item.thumb})-84px -155px no-repeat scroll`"
         >
          <!-- <img :src="item.thumb" alt="" /> -->
          <div class="desc">{{ item.name }}</div>
        </div>
      </div>
      <div class="down" style="background: #ffffff; height: 68px">
        <img src="../assets/image/arrow-down-black.png" alt="" />
      </div>
    </div>
    <!-- 产品展示 -->
    <div class="product">
      <div class="title">
        <span class="chinese">产品展示</span>
        <span class="english">PRODUCT DISPLAY</span>
      </div>
      <div class="swiper">
        <van-swipe :autoplay="4000" lazy-render indicator-color="#FFDBBD">
          <van-swipe-item v-for="(item, index) in indexBanner" :key="index">
            <div
              class="img-wrap"
            >
              <img :src="item.adv_thumb" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="down" style="background: #000; height: 53px">
        <img src="../assets/image/arrow-down.png" alt="" />
      </div>
    </div>
    <!-- 最新动态 -->
    <div class="news">
      <div class="title">
        <span class="chinese">最新动态</span>
        <span class="english" style="padding-top: 3px">NEWS</span>
      </div>
      <div class="time">
        <span style="font-size: 16px" @click="linktoaboutDetails(articleDetails.id)">{{articleDetails.name}}</span>
        <span style="font-size: 12px; padding-top: 3px"
          >发布时间：{{articleDetails.createtime}}</span
        >
      </div>
      <div class="news-desc">
        {{articleDetails.index_content}}
      </div>
      <div class="paging-wrap">
        <div class="paging">
          <span @click="up(articleDetails.id)">&lt;上一篇 </span>
          <span @click="next(articleDetails.id)">下一篇&gt;</span>
        </div>
      </div>
      <div class="down" style=" height: 100px;background:none;">
        <img src="../assets/image/arrow-down.png" alt="" />
      </div>
    </div>
    <!-- 地图 -->
    <!-- <div class="baidumap" id="allmap"></div> -->
    <div id="container" style="width: 100%;height: 242px"></div>
    <div class="info-wrap">
      <div class="info-list">
        <div class="info-item">
          <div class="info-name">
            <span class="division"></span>
            <div class="names" style="color:#000000;">门店名称</div>
          </div>
          <div class="detail">
            四川省成都市青羊区日月大道一段1501号万和中心4栋附218
          </div>
        </div>
        <div class="info-item">
          <div class="info-name">
            <span class="division"></span>
            <div class="names" style="color:#000000;">门店热线</div>
          </div>
          <div class="detail">028-6622 9888</div>
        </div>
        <div class="info-item">
          <div class="info-name">
            <span class="division"></span>
            <div class="names" style="color:#000000;">联系我们</div>
          </div>
          <div class="detail">CONTACT US</div>
        </div>
      </div>
      <div class="form-wrap">
        <div class="form-item">
          <div class="input-wrap">
            <span class="label">联系人</span>
            <input type="text" v-model="name" />
          </div>
          <div class="input-wrap">
            <span class="label">联系电话</span>
            <input v-model="tel" type="number" />
          </div>
        </div>
        <div class="form-item">
          <div class="input-wrap" style="width: 100%">
            <span class="label">备注信息</span>
            <textarea rows="4" v-model="remark"></textarea>
          </div>
        </div>
        <div class="form-item">
          <van-button type="primary" @click="submitBtn()">提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from 'AMap';

export default {

  name: "Home",
  data() {
    return {
      aboutList: [
        { imgUrl: require("../assets/image/about1.png"), name: "< 精湛工艺 >" },
        { imgUrl: require("../assets/image/about2.png"), name: "< 原料溯源 >" },
        { imgUrl: require("../assets/image/about3.png"), name: "< 腕表文化 >" },
      ],
      swiperList: [
        {
          img: require("../assets/image/swiper1.png"),
        },
        {
          img: require("../assets/image/swiper1.png"),
        },
        {
          img: require("../assets/image/swiper1.png"),
        },
      ],
      tel:'',
      remark:'',
      name:'',
      aboutUsList:'',
      bannerUsL:'',
      aboutImgList:'',
      indexBanner:'',
      articleDetails:'',
      show: false,
      activeNames: "1",
    };
  },
  created() {
    this.$emit("theme", false);
    this.videoList()
    this.aboutUs()
    this.aboutus_introduce()
    this.index_goods()
    this.index_article()
    this.indexGoods();
  },
  methods: {
    openMenu() {
      this.show = true;
    },
    indexGoods() {
      this.axios
        .post("https://api.wodelan.com/api/index/indexGoods")
        .then((res) => {
          this.yushouTitle = JSON.parse(res.data.data);
          console.log(this.yushouTitle);
        });
    },
    linktoYs(id) {
      this.$router.push({ path: "../views/goods-detail", query: { id: id } });
      this.show = false;
    },
    linktoAbout(){
      this.$router.push({path:'../views/about-us'})
      this.show = false;
    },
    goHone(){
      this.$router.push({path:'../views/home'})
      this.show = false
    },
    linktoby(id){
      if(id == 1){
        this.$router.push({path:'../views/repair-maintain'})
        this.show = false
      }
      if(id == 2){
        this.$router.push({path:'../views/maintain'})
        this.show = false
      }
      if(id == 3){
        this.$router.push({path:'../views/product'})
        this.show = false
      }
      if(id == 4){
        this.$router.push({path:'../views/watch'})
        this.show = false
      }
      if(id == 5){
        this.$router.push({path:'../views//myContent/myContent'})
        this.show = false
      }
    },
    initMap() {
      this.map = new AMap.Map("container", {
        resizeEnable: true,
        rotateEnable: false,
        pitchEnable: false,
        zoom: 14,
        pitch: 50,
        rotation: 0,
        //viewMode: "3D", //开启3D视图,默认为关闭
        expandZoomRange: false,
        center: [103.970297, 30.681193],
      });
      // var markerContent = '' +
      //   '<div class="custom-content-marker">' +
      //   '   <img style="width:5vw;height:8vw;" src="//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png">' +
      //   '   <div class="close-btn"  style="font-size:3vw;width:100px;height50px;border:1px solid #000000;position:relative;top:-10px;">123456</div>' +
      //   '</div>';
      this.marker = new AMap.Marker({
        position: this.map.getCenter(),
        // content: markerContent,
        // icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        offset: new AMap.Pixel(-3, -3),
        // 设置是否可拖拽
        draggable: true,
        cursor: "move",
      });
      this.marker.setMap(this.map);

      // 设置点标记的动画效果，此处为弹跳效果
      //this.marker.setAnimation("AMAP_ANIMATION_BOUNCE");
    },

    getLocation(location) {
      //赋值经度纬度和地址
      if (location) {
        this.form.lng = location.lng;
        this.form.lat = location.lat;
        this.form.projectAddress = location.address;
      }
    },
    videoList(){
      this.axios.post('https://api.wodelan.com/api/index/Banner').then(res =>{
        var wq = JSON.parse(res.data.data)
        console.log(wq)
        this.bannerUsL = wq[0].thumb
      })
    },
    aboutUs(){
      this.axios.post('https://api.wodelan.com/api/index/index_aboutus').then(res =>{
        this.aboutUsList = JSON.parse(res.data.data)
      })
    },
    aboutus_introduce(){
      this.axios.post('https://api.wodelan.com/api/index/aboutus_introduce').then(res =>{
        this.aboutImgList = JSON.parse(res.data.data)
      })
    },
    index_goods(){
      this.axios.post('https://api.wodelan.com/api/index/index_goods').then(res =>{
        this.indexBanner = JSON.parse(res.data.data)
      })
    },
    index_article(){
        this.axios.post('https://api.wodelan.com/api/index/index_article').then(res =>{
          this.articleDetails = JSON.parse(res.data.data)
        })
      },
    submitBtn(){
      if(this.name == ''){
        this.$toast('请填写联系人');
        return false
      }
      if(this.tel == ''){
        this.$toast('请填写联系电话');
        return false
      }
      if(this.remark == ''){
        this.$toast('请填写备注信息');
        return false
      }
      let data = {
          name:this.name,
          phone:this.tel,
          content:this.remark
      }
      this.axios.post('https://api.wodelan.com/api/index/contact_submit',data).then(res =>{
        console.log(res)
          if(res.data.code == 1){
            this.$toast(res.data.msg);
          }else{
            this.$toast(res.data.msg);
          }
      })
    },  
    up(id){
        let data = {
          aid : id -1
        }
        this.axios.post('https://api.wodelan.com/api/index/index_article',data).then(res =>{
          console.log(res)
          if(res.data.msg == '暂无数据'){
            this.$toast('暂无数据');
            return false
          }
          this.articleDetails = JSON.parse(res.data.data)
        })
    },
    next(id){
        let data = {
          aid : id + 1
        }
        this.axios.post('https://api.wodelan.com/api/index/index_article',data).then(res =>{
          console.log(res)
          if(res.data.msg == '暂无数据'){
            this.$toast('暂无数据');
            return false
          }
          this.articleDetails = JSON.parse(res.data.data)
        })
      },
    linktoaboutDetails(id){
      this.$router.push({path:'./aboutUsDetails',query:{id:id}})
    } , 
    // baiduMap() {
    //   var map = new BMap.Map("allmap"); // 创建地图实例
    //   map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    //   map.addControl(new BMap.NavigationControl());
    //   map.addControl(new BMap.ScaleControl());
    //   map.addControl(new BMap.OverviewMapControl());
    //   map.addControl(new BMap.MapTypeControl());
    //   // map.setMapStyle({ style: 'midnight' }) //地图风格
    //   map.clearOverlays();
    //   //将数据遍历
    //   //自定义信息窗口内容
    //   var sContent =
    //     '<div style="width:300px;"><p style="font-size:16px;font-weight:bold;margin-top: 10px;color:#D07852">' +
    //     this.position_NKYYGS.Name +
    //     '</p><p style="font-size:13px;margin: 5px 0;">地址：' +
    //     this.position_NKYYGS.address +
    //     '</p><p style="font-size:13px;margin: 5px 0;">电话：' +
    //     this.position_NKYYGS.phoneNumber +
    //     "</div>";
    //   var point = new BMap.Point(103.970297, 30.681193); // 创建点坐标
    //   var marker = new BMap.Marker(point);
    //   map.addOverlay(marker);
    //   map.centerAndZoom(point, 18); // 初始化地图，设置中心点坐标和地图级别
    //   // //点击图标时候调用信息窗口
    //   var infoWindow = new BMap.InfoWindow(sContent);
    //   marker.addEventListener("click", function () {
    //     this.openInfoWindow(infoWindow);
    //   });
    // },

    //下拉后返回顶部
    // scrollToTop(){
    //   this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   // console.log(this.scrollTop) 
    //   if(this.scrollTop == 0){
    //     this.show = true
    //   }
    // },
  },
  mounted() {
    // this.baiduMap();
    this.initMap();
    window.addEventListener('scroll', this.scrollToTop)
  },

};
</script>
<style lang="scss">
.home {
  .artisan {
    height: 558px;
    // background: #000 url(../assets/image/bg1.png) no-repeat;
    background-position: top center;
    background-size: 100%;
    display: flex;
    justify-content: center;
    img {
      // width: 268px;
      width:33%;
      height:40px; 
      // height: 81px;
      margin-top: 286px;
    }
  }
  .down {
    height: 188px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about {
    height: 799px;
    background: #fff;
    padding: 34px 28px 0;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: PingFang SC;
      color: #333333;
      .chinese {
        font-size: 20px;
      }
      .english {
        font-size: 12px;
        margin-top: 3px;
      }
    }
    .describe {
      margin: 25px 0 24px;
      line-height: 30px;
      font-family: PingFang SC;
      color: #333333;
      font-size: 12px;
    }
    .img-list {
      .img-item {
        position: relative;
        height: 137px;
        margin-bottom: 11px;
        img {
          width: 100%;
          height: 100%;
          clip-path: inset(0px 40px 30px 40px);
        }
        .desc {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 31px;
          line-height: 31px;
          background: rgba(211, 182, 125, 0.6);
          font-size: 13px;
          color: #fff;
          text-align: center;
        }
      }
    }
  }
  .product {
    height: 753px;
    background: #000000;
    padding-top: 45px;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: PingFang SC;
      color: #ffffff;
      .chinese {
        font-size: 20px;
      }
      .english {
        font-size: 12px;
        margin-top: 3px;
      }
    }
    .swiper {
      .van-swipe {
        .van-swipe-item {
          .img-wrap {
            height: 651px;
            background-position: top center;
            background-size: 100%;
            display: flex;
            align-items: center;
            img{
              width: 100%;
              // position: absolute;
              // top: calc(100% - );
            }
          }
        }
      }
    }
  }
  .news {
    // height: 799px;
    padding: 0 17px;
    background: #000 url(../assets/image/news-bg.png) no-repeat;
    background-position: top center;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    .title {
      padding-top: 336px;
      font-family: PingFang SC;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      .chinese {
        font-size: 20px;
      }
      .english {
        font-size: 14px;
      }
    }
    .time {
      display: flex;
      flex-direction: column;
      padding-top: 50px;
      font-family: PingFang SC;
      color: #ffffff;
    }
    .news-desc {
      // height: 95px;
      font-size: 12px;
      font-family: PingFang SC;
      line-height: 24px;
      color: #ffffff;
      padding-top: 50px;
      word-break:break-all;
     display:-webkit-box;/**对象作为伸缩盒子模型展示**/
     -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
     -webkit-line-clamp:3;/**显示的行数**/
     overflow:hidden;/**隐藏超出的内容**/
    }
    .paging-wrap {
      .paging {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        font-size: 14px;
        padding-top: 12px;
        span {
          padding: 0 11px;
        }
      }
    }
  }
  .baidumap {
    width: 100%;
    height: 242px;
    background: #fff;
  }
  .info-wrap {
    background: #ffffff;
    padding: 22px 18px 82px;
    .info-list {
      .info-item {
        font-size: 14px;
        color: #333333;
        border-bottom: 1px dashed;
        padding-bottom: 19px;
        margin-bottom: 25px;
        &:last-child {
          border: none;
          margin-bottom: 10px;
        }
        .info-name {
          display: flex;
          align-items: center;
          .division {
            width: 2px;
            height: 14px;
            background: #000000;
            margin-right: 8px;
          }
        }
        .detail {
          font-size: 12px;
          margin: 8px 0 0 10px;
        }
      }
    }
    .form-wrap {
      .form-item {
        display: flex;
        justify-content: space-between;
        .input-wrap {
          display: flex;
          flex-direction: column;
          width: 163px;
          margin-bottom: 15px;
          .label {
            font-size: 12px;
            color: #333;
            text-align: left;
            margin-bottom: 9px;
          }
          input {
            height: 30px;
            background: rgba(246, 246, 246, 0.9);
            border: none;
            outline: none;
            font-size: 14px;
            padding: 4px 10px;
            box-sizing: border-box;
          }
          textarea {
            width: 100%;
            background: rgba(246, 246, 246, 0.9);
            border: none;
            outline: none;
            font-size: 14px;
            padding: 4px 10px;
            box-sizing: border-box;
            resize: none;
          }
        }
        .van-button {
          margin-top: 5px;
          background: #000000;
          color: #ffffff;
          width: 156px;
          height: 37px;
          border-color: #000;
        }
      }
    }
  }
}
.menu {
  height: 53px;
  padding: 0 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: calc(100% - 52px);
  z-index: 9999;
  left: 0;
  top: 0;
}
.van-popup {
  .popup-wrap {
    .cross-wrap {
      padding: 15px 30px 51px;
      display: flex;
      justify-content: flex-end;
    }
    .van-collapse {
      .van-collapse-item {
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            padding: 6px 16px;
            .children-list {
              .children-item {
                font-size: 12px;
                height: 40px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
.closeHead{
  width: 6.5333vw;
  height: 6.5333vw;
}
.my-swipe{
  img{
    width: 100%;
  }
}
.updowm{
  position: absolute;
  top: 169.3333vw;
  z-index: 99;
  left:calc(50% - 10px);
}
.logo2{
  width: 13.2vw;
  height: 6.9333vw;
}
</style>
