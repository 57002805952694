<template>
    <div>
        <van-nav-bar title="订单详情" left-arrow @click-left="onClickLeft" />
        <div class="order_title">商品信息</div>
        <div class="order_row">
            <div class="order_img"><img src="../../assets/image/Clip.png" alt=""></div>
            <div class="order_left">
                <div class="order_list">
                    <div class="order_list_right">{{olist.series}}</div>
                    <div class="order_list_left">{{olist.amount}}</div>
                </div>
                <div class="order_list">
                    <div class="order_list_right order_list_rightTow">{{olist.goods_name}}</div>
                    <div class="order_list_left">x{{olist.num}}</div>
                </div>
                <div class="order_list">
                    <div class="order_list_right order_list_rightTow">表带尺寸{{olist.strap_size}}（厘米）</div>
                    <!-- <div class="order_list_left">18</div> -->
                </div>
            </div>

        </div>
        <div class="order_footer">
            <div class="footer_row">
                <div>
                    <div class="footerList">
                        <div class="list_right">运费</div>
                        <div class="list_left">￥0.00</div>
                    </div>
                    <div class="footerList">
                        <div class="list_right">总计</div>
                        <div class="list_left list_price">￥{{olist.amount * olist.num}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 订单信息 -->
        <div class="order_message">
            <div class="order_message_title">订单信息</div>
            <div class="order_message_list">
                <div class="order_message_list_right">配送地址</div>
                <div class="order_message_list_left">{{olist.name}}{{olist.phone}}<br>{{olist.ssq}}{{olist.address}}</div>
            </div>
            <div class="order_message_list">
                <div class="order_message_list_right">订单号</div>
                <div class="order_message_list_left"> {{olist.order_no}}</div>
            </div>
            <div class="order_message_list">
                <div class="order_message_list_right">创建日期</div>
                <div class="order_message_list_left"> {{olist.createtime}}</div>
            </div>
            <div class="order_message_list">
                <div class="order_message_list_right">付款时间</div>
                <div class="order_message_list_left"> {{olist.pay_time}}</div>
            </div>
            <div class="order_message_list">
                <div class="order_message_list_right">发货时间</div>
                <div class="order_message_list_left"> {{olist.ship_time}}</div>
            </div>
            <div class="order_message_list">
                <div class="order_message_list_right">成交时间</div>
                <div class="order_message_list_left"> {{olist.make_time}}</div>
            </div>
            <div class="order_message_list">
                <div class="order_message_list_right">支付方式</div>
                <div class="order_message_list_left"><img src="../../assets/image/wx.png" alt=""></div>
            </div>
            <div class="kefu">
                <div class="kefuTop"><img src="../../assets/image/phone.png" alt="">客服热线</div>
                <div class="kefufooter">028-66229888</div>
            </div>
            <!-- 底部 -->
            <div class="footer_btn">
                <div class="footer_btn_row" v-if="olist.status == 0">
                    <div class="clear">取消订单</div>
                    <div class="pay">继续支付</div>
                </div>
                <!-- <div class="footer_btn_row" v-else></div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            id:'',
            olist:'',
        }
    },
    created(){
        this.id = this.$route.query.id
        console.log(this.id)
        this.orderList()
    },
    methods:{
        orderList(){
            let data ={
                order_id : this.id
            }
            this.axios.post('https://api.wodelan.com/api/index/orderDetail',data).then(res =>{
                console.log(JSON.parse(res.data.data))
                var ss = JSON.parse(res.data.data)
                this.total = ss.length + 1
                this.olist = ss
            })
        },
        onClickLeft(){
           history.go(-1)
        },
    }
}
</script>
<style >
.order_title{
    width: 91.6vw;
    margin: 0 auto;
    font-size: 3.3333vw;
    margin-top: 4.6667vw;
    font-weight: bold;
    color: #666666;

}
.order_row{
    width: 91.6vw;
    margin: 0 auto;
    margin-top: 4vw;
    display: flex;
    justify-content: space-between;
}
.order_img{
    width: 21.7333vw;
    height: 21.7333vw;

}
.order_img img{
    width: 100%;
    height: 100%;
}
.order_left{
    width: 66.6667vw;
}
.order_list{
    display: flex;
    justify-content: space-between;
}
.order_list_right{
    font-size: 3.3333vw;
    color: #333333;
    font-weight: bold;
    line-height: 4.6667vw;
}
.order_list_left{
    font-size: 3.3333vw;
    font-weight: bold;
    color: #333333;
    line-height: 4.6667vw;
}
.order_list_rightTow{
    font-weight: 400;
}
.order_footer{
    margin-top: 3.0667vw;
    width: 89.2vw;
    margin: 0 auto;
    padding-bottom: 3.4667vw;
}
.footer_row{
    display: flex;
    justify-content: flex-end;
}
.footerList{
    margin-top: 1.4667vw;
    display: flex;
    width: 41.0667vw;
    justify-content: space-between;

}
.list_right{
    font-size: 3.3333vw;
    color: #666666;
}
.list_left{
    font-size: 3.3333vw;
    color: #666666;
}
.list_price{
    color: #FF0F0F;
    font-weight: bold;
}
.order_message{
    width: 100%;
    border-top: 2.4vw solid #F6F6F6;
}
.order_message_title{
    width: 91.8667vw;
    margin: 0 auto;
    font-size: 3.3333vw;
    font-weight: bold;
    color: #666666;
    margin-top: 1.8667vw;

}
.order_message_list{
    width: 91.8667vw;
    margin: 0 auto;
    margin-top: 6.1333vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.order_message_list_left{
    width: 54.2667vw;
    color: #000000;
    font-size: 3.3333vw;
    text-align: right;
}
.order_message_list_right{
    font-size: 3.3333vw;
    color: #666666;
}
.order_message_list_left img{
    width: 17.2vw;

}
.kefu{
    width: 91.3333vw;
    margin: 0 auto;
    height: 28.5333vw;
    background: #F6F6F6;
    margin-top: 10.2667vw;
    
}
.kefuTop{
    display: flex;
    justify-content: center;
    font-size: 3.3333vw;
    font-weight: bold;
    padding-top: 9.6vw;
}
.kefuTop img{
    width: 3.8667vw;
    height: 3.8667vw;
}
.kefufooter{
    display: flex;
    justify-content: center;
    font-size: 3.3333vw;
    font-weight: bold;
}
.footer_btn{
    margin-top: 18.6667vw;
    height: 14.9333vw;
    border-top: 0.2666vw solid #F6F6F6;
    display: flex;
    justify-content: flex-end;
    align-content: center;
}
.clear{
    font-size: 3.3333vw;
    margin-right: 4.2667vw;
}
.footer_btn_row{
    display: flex;
    align-items: center;
}
.pay{
    width: 22.1333vw;
    height: 7.7333vw;
    background: #000000;
    color: #ffffff;
    font-size: 3.3333vw;
    text-align: center;
    line-height: 7.7333vw;
    margin-right: 5.8667vw;
}
</style>
