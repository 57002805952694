<template>
  <div class="address-detail">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    <div class="address-wrap">
      <van-form ref="form">
        <!-- 姓名 -->
        <van-field
          label="姓名"
          v-model="name"
          required
          :rules="nameRules"
          name="name"
        ></van-field>
        <!-- 手机号 -->
        <van-field
          label="手机号"
          v-model="tel"
          required
          :rules="telRules"
          name="tel"
        ></van-field>
        <!-- 省 -->
        <div class="select_row">
          <div class="select_name">*省</div>
          <select name="" id="" class="selectList" v-model="shengValue" @change="shengClick()">
            <option  v-for="(item,index) in shengList" :key="index" :value="item.parentId">{{item.areaName}}</option>
          </select>
        </div>

        <!-- 市 -->
        <div class="select_row">
          <div class="select_name">*市</div>
          <select name="" id="" class="selectList" v-model="shiValue" @change="shiClick()" :disabled="isDisabled">
            <option  v-for="(item,index) in shiList" :key="index" :value="item.parentId">{{item.areaName}}</option>
          </select>
        </div>
        <!-- 区/县 -->
        <div class="select_row">
          <div class="select_name">*区</div>
          <select name="" id="" class="selectList" v-model="xianValue" :disabled="isDisabled2">
            <option  v-for="(item,index) in xianList" :key="index" :value="item.parentId">{{item.areaName}}</option>
          </select>
        </div>
        <!-- 详细地址 -->
        <van-field
          label="详细地址"
          v-model="address"
          required
          :rules="addressRules"
          name="address"
        ></van-field>
        <!-- 邮政编码 -->
        <van-field label="邮政编码" v-model="code" name="code"></van-field>
        <div class="ischeck">
          <span class="change" @click="setAddress()">
            <span v-if="isDefault" class="checked"></span>
          </span>
          <span @click="setAddress()">设为默认地址</span>
        </div>
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker
            :columns="curColumns"
            @change="onChange"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-button type="primary" block @click="submit">提交</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressDetail",
  data() {
    return {
        isDisabled:true,
        isDisabled2:true,
        isDefault: false,
        name:'',
        tel:'',
        code:'',
        address:'',
      title: "地址",
      nameRules: [
        {
          required: true,
          message: "姓名不能为空",
          trigger: "onBlur",
        },
      ],
      telRules: [
        { required: true, message: "手机号码不能为空", trigger: "onBlur" },
      ],
      provinceRules: [
        {
          required: true,
          message: "选择省份",
          trigger: "onChange",
        },
      ],
      cityRules: [
        {
          required: true,
          message: "选择城市",
          trigger: "onChange",
        },
      ],
      areaRules: [
        {
          required: true,
          message: "选择区/县",
          trigger: "onChange",
        },
      ],
      addressRules: [
        {
          required: true,
          message: "请输入详细地址",
          trigger: "onBlur",
        },
      ],
      showPicker: false,
      provinces: ["杭州", "宁波", "温州", "嘉兴", "湖州","四川"],
      citys: ["1", "2", "3", "4", "5"],
      areas: ["1", "2", "3", "4", "5"],
      curColumns: [],
      curPicker: 0,
      shiList:[],
      shengValue:'',
      shiValue:'',
      xianValue:'',
      shengList:[],
      xianList:[],
      addList:[],
      addId:'',
    };
  },
  created() {
    if(this.$route.query.item){
    var  add = this.$route.query.item
    this.addList = JSON.parse(add)
    console.log(this.addList)
    }

    this.name = this.addList.ship_name
    this.tel = this.addList.ship_tel
    this.shengValue = this.addList.province_id
    this.shengClick()
    this.shiValue = this.addList.city_id
    this.shiClick()
    this.xianValue = this.addList.area_id
    this.addId = this.addList.id
    this.address = this.addList.ship_addr
    this.code = this.addList.ship_zip
    if(this.addList.is_default == 0){
      this.isDefault = false
    }else{
      this.isDefault = true
    }
    // this.showPicker = this.addList.is_default
    this.$emit("header", false);
    this.openPicker()
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onChange(value) {
      console.log('11')
      if (this.curPicker == 1) {
        this.form.province = value;
      }
      if (this.curPicker == 2) {
        this.form.city = value;
      }
      if (this.curPicker == 3) {
        this.form.area = value;
      }
    },
    onConfirm() {
      if (!this.form.province && this.curPicker == 1) {
        this.form.province = this.provinces[0];
      }
      if (!this.form.city && this.curPicker == 2) {
        this.form.city = this.citys[0];
      }
      if (!this.form.area && this.curPicker == 3) {
        this.form.area = this.areas[0];
      }
      this.showPicker = false;
    },
    openPicker() {
      // console.log(this.shengvalue)
      //   let data = {
      //       parentId : this.shengvalue
      //   }
      this.axios.post('https://api.wodelan.com/api/index/provinceList').then(res =>{
        this.shengList = JSON.parse(res.data.data)
      })

    },
    shengClick(){
      this.shiValue=''
      this.xianValue=''
      this.isDisabled2=true
      console.log(this.shengValue)
        let data = {
            parentId : this.shengValue
        }
      this.axios.post('https://api.wodelan.com/api/index/provinceList',data).then(res =>{
        console.log(JSON.parse(res.data.data))
        this.shiList = JSON.parse(res.data.data)
        console.log(this.shiList)
        console.log('=================',this.shengValue);
        // this.shengValue!==''?this.isDisabled=false:this.isDisabled=true//初始状态}
        if(this.shengValue){
          this.isDisabled=false
        }else{
          this.isDisabled=true
        }
      })
    },
    shiClick(){
      console.log(this.shiValue)
        let data = {
            parentId : this.shiValue
        }
      this.axios.post('https://api.wodelan.com/api/index/provinceList',data).then(res =>{
        console.log(JSON.parse(res.data.data))
        this.xianList = JSON.parse(res.data.data)
        console.log(this.shiList)
        if(this.shiValue){
          this.isDisabled2=false
        }else{
          this.isDisabled2=true
        }
      })
    },
    //设为默认地址
    setAddress() {
      this.isDefault = !this.isDefault;
    },
    submit() {
      // this.$refs.form
      //   .validate()
      //   .then(() => {
      //     console.log(this.form);
      //   })
      //   .catch(() => {});
      let data = {
        id:this.addId,
        province_id:this.shengValue,
        city_id:this.shiValue,
        area_id:this.xianValue,
        user_id:localStorage.getItem('user_id'),
        ship_name:this.name,
        ship_tel:this.tel,
        ship_addr:this.address,
        ship_zip:this.code,
        is_default:this.isDefault,
      }
      this.axios.post('https://api.wodelan.com/api/index/addressUpdate',data).then(res =>{
        console.log(JSON.parse(res.data.data))
        console.log(res)
        if(res.data.code == 1){
          this.$toast(res.data.msg);
          setTimeout(() => {
            this.$router.push({path:'./myContent'})
          }, 1000);
        }else{
          this.$toast(res.data.msg);
        }
      })
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.address-detail {
  .address-wrap {
    padding: 22px 18px;
    .van-form {
      .van-field {
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        .van-field__label {
          width: 100%;
          font-size: 14px;
          color: #333;
          margin-bottom: 8px;
        }
        .van-field__value {
          .van-field__body {
            .van-field__control {
              height: 48px;
              border: 1px solid #f0f0f0;
              padding: 0 8px;
            }
          }
        }
        &::after {
          display: none;
        }
      }
      .select-wrap {
        position: relative;
        .van-icon {
          position: absolute;
          right: 15px;
          top: 48px;
          font-size: 20px;
        }
      }
      .ischeck {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 50px;
        .change {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;
          border: 1px solid #707070;
          margin-right: 6px;
          .checked {
            width: 10px;
            height: 10px;
            background: #333;
          }
        }
      }
      .van-button {
        background: #000000;
        color: #ffffff;
        width: 270px;
        margin: auto;
        height: 44px;
        border-color: #000;
        font-size: 18px;
        margin-bottom: 13px;
      }
    }
  }
}
.select_row{
  width: 91.0667vw;
  margin: 0 auto;
}
.select_name{
  font-size: 3.3333vw;
  color: #333333;
  // margin-top: 5.3333vw;
}
.selectList{
  width: 91.0667vw;
  margin-top: 2.4vw;
  height: 12.4vw;
  border: 0.2666vw solid #F0F0F0;
  border-radius: 0.6667vw;
  margin-bottom: 5.3333vw;
  outline: none;
}
</style>
