<template>
  <div class="login">
    <van-nav-bar title="登录" left-arrow @click-left="onClickLeft" />
    <div class="wrap">
      <div class="title">欢迎回来</div>
      <div class="form-wrap">
        <div class="input-wrap" v-if="isAccount">
          <div class="label-wrap">
            <span class="label">手机号</span>
            <span @click="nameLogin()">账号密码登录</span>
          </div>

          <input type="text" v-model="tel" />
        </div>
        <div class="input-wrap" v-else>
          <div class="label-wrap">
            <span class="label">手机号</span>
            <span @click="yzmLogin()">动态验证码登录</span>
          </div>

          <input type="text" v-model="tel" />
        </div>
        <div
          :class="isAccount ? 'account-input-wrap input-wrap ' : 'input-wrap'"
          v-if="isAccount"
        >
          <span class="label-wrap">
            <span class="label">手机验证码</span>
          </span>
          <div style="display: flex; align-items: center">
            <input
              type="text"
              style="width: 211px"
              v-model="verification"
            />
            <span style="font-size: 12px; flex: 1; text-align: center"
              v-show="zhaohuipsd"
              @click="yanzhengmaBtn()">获取手机验证码</span
            >
            <span class="passwordYanzhengmaBtn"  v-show="!zhaohuipsd">{{zhaohuiTime}}s后获取</span>
          </div>
        </div>
        <div class="input-wrap" v-else>
          <div class="label-wrap">
            <span class="label">密码</span>
          </div>
          <input type="password" v-model="password" />
          <div class="label-wrap" style="margin-top: 19px">
            <span class="label" @click="loginRegister()">注册账号</span>
            <span class="label" @click="wjpassword()">忘记密码</span>
          </div>
        </div>

        <div class="input-wrap">
          <van-button type="primary" @click="loginBtn()">登录</van-button>
          <van-button type="primary" style="background: #ffffff; color: #000000"
            @click="weixinClick()"
            >微信快捷登录</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const getUrlParam = (name) => {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
export default {
  name: "Login",
  data() {
    return {
      tel:'',
      isAccount: false,
      verification:'',
      password:'',
      zhaohuipsd:true,
      yanzhengmaList:'',
      yanzhengma:true,
      zhaohuiTime:0,
      code:'',
      useropenid:"",
      openidDetails:'',
    };
  },
  created() {
    this.$emit("header", false);
    console.log(this.code)
    // localStorage.setItem('openId','')
    this.getUrlParam()
    // var code = this.$route.query.code
    // this.codelogin(code)
  },
  methods: {
    codelogin(code){
        this.openidDetails = localStorage.getItem('openId')
        if(this.openidDetails == null || this.openidDetails == ''){
          this.openidDetails = ''
        }
        this.axios.get('https://api.wodelan.com/api/index/GetUserInfo',{
          params:{
            code : code,
            openid:this.openidDetails
          }
        }).then(res => {
          console.log(res) 
          localStorage.setItem('openId',res.data.data.openid)
          localStorage.setItem('username',res.data.data.username)
          localStorage.setItem('user_id',res.data.data.user_id)
          localStorage.setItem('name',res.data.data.mobile)
          if(res.data.data.status == 0){
            this.$toast('登陆成功，请绑定手机号');
            setTimeout(() => {
              this.$router.push({path:'./binding-phone'})
            }, 1000);
          }else{
            this.$toast('登陆成功');
            // console.log('登陆成功')
            this.$router.push({path:'/myContent'})
            // this.$router.go(-2)

            // const curr = localStorage.getItem('preRoute')
            // let ids = curr.split("=")
            // if (!curr) {
            //   this.$router.push({ path: "/myContent" });
            // } else {
            //   this.$router.push({ path: curr , query: { id: ids[1] }});
            // }

          }
        })
    },
    onClickLeft() {
      history.go(-1)
    },
    loginBtn(){
      if(this.isAccount == false){
        if(this.tel == ''){
          this.$toast('请填写手机号');
          return false
        }
        if(this.password == ''){
          this.$toast('请填写密码');
          return false
        }
        let data = {
          account : this.tel,
          password : this.password
        }
        this.axios.post('https://api.wodelan.com/api/user/login',data).then(res =>{
          console.log(res)
            if(res.data.code == 1){
                localStorage.setItem('username',this.tel)
                localStorage.setItem('password',this.password)
                localStorage.setItem('token',res.data.data.userinfo.token)
                localStorage.setItem('user_id',res.data.data.userinfo.user_id)
                localStorage.setItem('name',res.data.data.userinfo.mobile)
                // 登录成功
                this.$toast(res.data.msg)
                setTimeout(() => {
                    this.$router.push({path:'/myContent'})
                    // this.$router.go(-1)

                    // const curr = localStorage.getItem('preRoute')
                    // let ids = curr.split("=")
                    // if (curr == null) {
                    //   this.$router.push({ path: "/myContent" });
                    // } else {
                    //   this.$router.push({ path: curr , query: { id:ids[1] }});
                    // }

                }, 1000);
            }else{
                this.$toast(res.data.msg);
            }
        })
      }else{
        let data = {
          mobile : this.tel,
          captcha : this.verification
        }
        this.axios.post('https://api.wodelan.com/api/user/mobilelogin',data).then(res =>{
          console.log(res)
          if(res.data.code == 1){
              localStorage.setItem('user_id',res.data.data.userinfo.user_id)
              localStorage.setItem('token',res.data.data.userinfo.token)
              localStorage.setItem('name',res.data.data.userinfo.mobile)
              this.$toast(res.data.msg);
              this.$router.push({path:'/myContent'})
              // this.$router.go(-1)

              // const curr = localStorage.getItem('preRoute')
              // let ids = curr.split("=")
              // if (curr == null) {
              //   this.$router.push({ path: "/myContent" });
              // } else {
              //   this.$router.push({ path: curr , query: { id: ids[1] }});
              // }

          }else{
              this.$toast(res.data.msg);
          }
        })
      }

    },
    loginRegister(){
      this.$router.push({path:'./register'})
    },
    wjpassword(){
      this.$router.push({path:'./forget'})
    },
    yzmLogin(){
      this.isAccount = true
    },
    nameLogin(){
      this.isAccount = false
    },
    yanzhengmaBtn(){
      if(this.tel == ''){
        this.$toast('请输入手机号');
        return false
      }
      let data = {
          type:'mobilelogin',
          mobile:this.tel
      }
      this.axios.post('https://api.wodelan.com/api/user/sendcaptcha',data).then(res =>{
          console.log(res)
          if(res.data.code == 1){
              this.$toast(res.data.msg);
              this.zhaohuipsd = false
              this.zhaohuiTime = 60
              var auth_timetimer  = setInterval(() =>{
                  this.zhaohuiTime --;
                  if(this.zhaohuiTime <= 0){
                      this.zhaohuipsd = true;
                      clearInterval(auth_timetimer)
                  }
              },1000)
          }else{
              this.$toast(res.data.msg);
          }
      })
    },
     getUrlParam (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      let url = window.location.href.split('#')[0]
      let search = url.split('?')[1]
      if (search) {
        var r = search.substr(0).match(reg)
        if (r !== null) return unescape(r[2])
        return null
      } else {
        return null
      }
    },
    // 微信登陆
    weixinClick(){
      console.log('点击微信登陆')
      const APPId = 'wxc4ad4d3867c8ac84'
      let code = getUrlParam("code");
      this.code = code
      localStorage.setItem('code',code)
      console.log(this.code)
      // let local = window.location.href;
      let local = 'https://h5.wodelan.com/login'
      // let local = encodeURIComponent(window.location.href)
      // let local = 'https://localhost:8080/binding-phone'
      // let local = 'https://api.wodelan.com'
      if(code == null || code == ''){
        window.location.href = 
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPId + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=200#wechat_redirect'
      }else{
        this.codelogin(code)
      }

      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        console.log('是微信')
      return true;
      } else {
        console.log('不是微信')
        return false;
      }

    },
  },
  mounted() {
    this.weixinClick()
  },
};
</script>
<style lang="scss">
.login {
  .wrap {
    padding: 19px 13px;
    .title {
      font-size: 25px;
      font-weight: bold;
    }
    .form-wrap {
      margin-top: 35px;
      .input-wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 34px;
        .label-wrap {
          font-size: 16px;
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;
        }

        input {
          height: 50px;
          font-size: 16px;
          padding: 0 10px;
        }
      }
      .account-input-wrap {
        margin-bottom: 75px;
      }
      .privacy {
        display: flex;
        align-items: center;
        margin: 18px 0;
        span {
          font-size: 12px;
          margin-left: 10px;
        }
      }
      .van-button {
        background: #000000;
        color: #ffffff;
        height: 55px;
        border-color: #000;
        font-size: 18px;
        margin-bottom: 13px;
      }
    }
  }
}
.passwordYanzhengmaBtn{
  font-size: 3.33vw;
  margin-left: 2vw;
}
</style>
