<template>
  <div class="product homeTop">
    <div class="product-img">
      <img src="../assets/image/product1.png" alt="" />
    </div>
    <div v-for="(item,index) in goodsList" :key="index">
      <div class="product-title" v-if="index == '男士手表'">
        <div class="divider-wrap">
          <span class="divider-left"></span>
          <span class="divider-text">{{index}}</span>
          <span class="divider-right"></span>
        </div>
        <div class="englist-name">MENSWATCH</div>
      </div>
      <div class="product-title" v-if="index == '女士手表'">
        <div class="divider-wrap">
          <span class="divider-left"></span>
          <span class="divider-text">{{index}}</span>
          <span class="divider-right"></span>
        </div>
        <div class="englist-name">WOMENSWATCH</div>
      </div>
      <div class="product-title" v-if="index == '精品腕表'">
        <div class="divider-wrap">
          <span class="divider-left"></span>
          <span class="divider-text">{{index}}</span>
          <span class="divider-right"></span>
        </div>
        <div class="englist-name">LUXURYWATCHES</div>
      </div>
      <div class="product-title" v-if="index == '钟表周边'">
        <div class="divider-wrap">
          <span class="divider-left"></span>
          <span class="divider-text">{{index}}</span>
          <span class="divider-right"></span>
        </div>
        <div class="englist-name">CLOCKAROUND</div>
      </div>
      <div class="product-title" v-if="index == '文创产品'">
        <div class="divider-wrap">
          <span class="divider-left"></span>
          <span class="divider-text">{{index}}</span>
          <span class="divider-right"></span>
        </div>
        <div class="englist-name">CULTURALANDCREATIVEPRODUCT</div>
      </div>
      <div class="product-list">
        <div class="product-item" v-for="v in item" :key="v.id" @click="linktoXq(v.id)">
          <img :src="v.thumb" alt="" />
          <span class="product-name">{{v.name}}</span>
          <span class="product-price">￥{{v.price}}</span>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "Product",
  data() {
    return {
      goodsList:'',
      page:1,
      rows:40,
    };
  },
  created() {
    this.goods()
    this.isBlack = true
  },
  methods: {
    goods(){
      let data = {
          page:this.page,
          rows:this.rows,
          type:2,
      }
      this.axios.post('https://api.wodelan.com/api/index/goodsList',data).then(res =>{
          console.log(res)
          console.log(JSON.parse(res.data.data))
          this.goodsList = JSON.parse(res.data.data)
          console.log(this.goodsList)
      })
    },
    linktoXq(id){
      console.log(id)
      this.$router.push({path:'./goods-detail',query:{id:id}})
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.product {
  .product-img {
    width: 100%;
    height: 145px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .product-title {
    .divider-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      margin-top: 17px;
      .divider-left,
      .divider-right {
        width: 58px;
        height: 1px;
        background: #000000;
      }
      .divider-text {
        font-size: 16px;
        color: #333333;
        padding: 0 9px;
      }
    }
    .englist-name {
      display: flex;
      justify-content: center;
      font-size: 12px;
      color: #333333;
      margin: 3px 0 25px;
    }
  }
  .product-list {
    padding: 0 9px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .product-item {
      display: flex;
      flex-direction: column;
      width: 48.6%;
      margin-bottom: 18px;
      img {
        width: 46.6667vw !important;
        height: 46.6667vw !important;
      }
      .product-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        color: #b6a379;
        padding: 4px 0;
      }
      .product-price {
        font-size: 14px;
        font-weight: bold;
        color: #000;
      }
    }
  }
}
</style>
